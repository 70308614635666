<template>
    <div class="modal-status-form-confirmation">
        <div class="modal-status-form-confirmation__title"
            v-text="'Подтверждение'"
        />
        <div class="modal-status-form-confirmation__description">
            Для{{'\xa0'}}подтверждения на{{'\xa0'}}номер телефона {{ phone }} был{{'\xa0'}}отправлен код.
            <br>
            Пожалуйста, введите код ниже:
        </div>
        <div class="modal-status-form-confirmation__code">
            <ui-input-character-set
                isAutofocus
                v-bind:size="codeLength"
                v-model="code"
            />
        </div>
        <div class="modal-status-form-confirmation__description"
            v-if="requestCooldownLeft > 0"
            >
            Запросить код повторно можно через {{ cooldownText }}
        </div>
        <div class="modal-status-form-confirmation__description"
            v-else
            >
            <ui-trigger
                v-on:click="getCode"
                >
                Получить новый код
            </ui-trigger>
        </div>
        <div class="modal-status-form-confirmation__button">
            <ui-button
                v-on:click="finish"
                v-bind:disabled="!code || code.length < codeLength"
                >
                Отправить форму
            </ui-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'modal-status-form-confirmation',
    data: () => ({
        requestTime: null,
        cooldown: 300, // seconds
        requestCooldownLeft: 0,
        interval: null,
        code: null,
        codeLength: 6,
    }),
    computed: {
        ...mapState('status', [
            'estateId',
            'getFormDataResult',
            'sendOtpResult',
            'sendOtpError',
        ]),
        phone() {
            return this.getFormDataResult?.form.values.phone.value;
        },
        cooldownText() {
            const minutes = Math.floor(this.requestCooldownLeft / 60);
            const seconds = this.requestCooldownLeft % 60;
            if (minutes > 0) {
                return `${minutes} ${utils.common.getDeclension(minutes, [ 'минуту', 'минуты', 'минут' ])} и ${seconds} ${utils.common.getDeclension(seconds, [ 'секунда', 'секунды', 'секунд' ])}`;
            }
            return `${seconds} ${utils.common.getDeclension(seconds, [ 'секунду', 'секунды', 'секунд' ])}`;
        },
    },
    methods: {
        getCode() {
            this.$store.dispatch('status/sendOtp', { id: this.estateId, phone: this.phone });
        },
        finish() {
            this.$store.dispatch('status/updateFormData', {
                id: this.estateId,
                formData: {
                    values: {
                        smsCode: this.code,
                    },
                },
                onSuccess: () => {
                    this.$store.dispatch('status/sendForm', {
                        id: this.estateId,
                        onSuccess: result => {
                            if (result.errors) {
                                this.handleError(result);
                            } else {
                                this.$emit('completed');
                            }
                        },
                        onError: (error) => {
                            this.handleError(error);
                        },
                    });
                },
            });
        },
        handleError(data) {
            this.$store.commit('modals/push', {
                name: 'generic',
                props: {
                    title: 'Ошибка',
                    // text: `Не удалось отправить данные, повторите попытку или попробуйте позже`,
                    text: `[DEBUG] ${JSON.stringify(data)}`,
                    theme: 'error',
                },
            });
        },
    },
    beforeMount() {
        this.getCode();
        this.interval = setInterval(() => {
            if (this.requestTime) {
                const requestCooldownLeft = Math.round((this.requestTime * 1000 - Date.now()) / 1000);
                if (requestCooldownLeft < 0) {
                    this.requestCooldownLeft = 0;
                    this.requestTime = null;
                } else {
                    this.requestCooldownLeft = requestCooldownLeft;
                }
            }
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    watch: {
        sendOtpResult(newVal) {
            this.requestTime = newVal.sendTime + this.cooldown;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-confirmation {
    &__title {
        .typography-heading();

        margin-bottom: 24px;

        text-align: center;
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;

        text-align: center;
        &._error {
            color: @color-accent-negative;
        }
    }
    &__code {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
    }
    &__button {
        width: fit-content;
        margin: 0 auto;
    }
}
</style>
