<template>
    <div class="modal-edit-investment-round">
        <div class="modal-edit-investment-round__title">
            Добавление раунда
        </div>
        <div class="modal-edit-investment-round__description">

        </div>
<!--        <div class="modal-edit-investment-round__heading">
            {{subtitle}}
        </div>-->
        <div class="modal-edit-investment-round__form">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-on:update="onFormUpdate"
            />
            <div class="modal-edit-investment-round__form-error"
                v-if="error"
                v-text="error"
            />
        </div>
        <div class="modal-edit-investment-request__button">
           <ui-button
                v-on:click="save"
                >
                Добавить раунд
            </ui-button>
        </div>
    </div>
</template>

<script>
// import { mapState } from 'vuex';
import forms from '~/forms';
import { mainClient } from '~/network';

export default {
    name: 'modal-create-investment-round',
    props: {
        /**
         * @type {InvestmentRoundDashboardTransformed[]}
         */
        rounds: {
            type: Array,
        },
        estate: {
            type: Object,
        },
    },
    data: () => ({
        error: null,
        fields: {
            target: {
                ...forms.fields.admin.estates.target,
                value: 0,
                isHalf: true,
            },
            sharePrice: {
                ...forms.fields.admin.estates.sharePrice,
                value: 0,
                isHalf: true,
            },
            minimumInvestmentAmount: {
                ...forms.fields.admin.estates.minimalInvestmentAmount,
                value: 0,
            },
            // isActive: {
            //     ...forms.fields.admin.estates.isActive,
            //     value: false,
            // },
        },
        newRounds: [],
    }),
    validations: {
        fields: {
            target: { ...forms.validations.genericRequired },
            sharePrice: { ...forms.validations.genericRequired },
            minimumInvestmentAmount: { ...forms.validations.genericRequired },
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
        onFormUpdate(data) {
            this.$v.fields.$touch();
            this.fields = data;
        },
        async save() {
            this.$v.fields.$touch();
            if (this.$v.fields.$invalid) {
                return;
            }
            const { data, error } = await mainClient.estates.requests.createEstateRound({
                estateId: this.estate.id,
                target: this.fields.target.value,
                sharePrice: this.fields.sharePrice.value,
                minimumInvestmentAmount: this.fields.minimumInvestmentAmount.value,
                // isActive: this.fields.isActive.value,
                isActive: false,
            });
            if (!error) {
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estate.id });
                this.close();
            } else {
                this.error = '';
                for (const errorKey in error) {
                    this.error += `${error[errorKey]?.toString()}.`;
                } // TODO double code
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-edit-investment-round {
    .modal();

    display: flex;
    flex-direction: column;
    gap: 24px;

    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 75%);
        &-container {
            width: 50px;
            padding-top: 8px;
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__heading {
        .typography-heading();
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;
        &-date {
            color: @color-gray-main;
        }
    }
    &__form {
        &-error {
            .typography-body();

            margin-top: 4px;

            color: @color-accent-negative;
        }
    }
    &__button {
        width: 206px;
        margin: 0 auto 24px;
    }
    &__cancel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        margin: 0 auto;

        color: @color-accent-negative;

        cursor: pointer;
        &-icon {
            margin-right: 10px;

            font-size: 2rem;
        }
        &-text {
            .typography-heading();
        }
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
