<template>
    <div class="modal-status-form">
        <template v-if="isLoading">
            <transition name="fade">
                <div class="modal-status-form__loader">
                    <div class="modal-status-form__loader-container">
                        <ui-loader/>
                    </div>
                </div>
            </transition>
        </template>
        <div class="modal-status-form__back"
            v-if="currentStepIndex > 0"
            v-on:click="backHandler"
            >
            <div class="modal-status-form__back-icon">
                <icon icon="chevron-left" />
            </div>
            <div class="modal-status-form__back-title"
                v-text="'Назад'"
            />
        </div>
        <div class="modal-status-form__content">
            <modal-status-form-success v-if="isFormSent" />
            <modal-status-form-confirmation v-else-if="isLastStepCompleted" v-on:completed="onFormSend"/>
            <modal-status-form-auth v-else-if="getFormDataResult && !isAuthorized" />
            <!-- <modal-status-form-estate v-else-if="!estateId" /> -->
            <modal-status-form-step v-else-if="currentStep" v-on:completed="onLastStepComplete" />
        </div>
        <div class="modal-status-form__botline">
            <div class="modal-status-form__botline-support">
                Если вы нашли ошибку в данных или у вас есть вопросы, обратитесь в <a href="#">Службу поддержки</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'modal-status-form',
    props: {
        estate: {
            type: Object,
        },
    },
    data: () => ({
        isLastStepCompleted: false,
        isFormSent: false,
    }),
    computed: {
        ...mapGetters('status', [
            'currentStep',
            'getAuthUrlResult',
            'isAuthorized',
        ]),
        ...mapState('status', [
            'isCurrentStepValid',
            'currentStepIndex',
            'estateId',
            'givenEstate',
            'getFormDataResult',
            'getFormDataIsLoading',
            'createFormDataIsLoading',
            'updateFormDataIsLoading',
            'getAuthUrlIsLoading',
            'getBankInfoIsLoading',
            'sendOtpIsLoading',
            'sendFormIsLoading',
        ]),
        isLoading() {
            return this.getFormDataIsLoading ||
                this.createFormDataIsLoading ||
                this.updateFormDataIsLoading ||
                this.getAuthUrlIsLoading ||
                this.getBankInfoIsLoading ||
                this.sendOtpIsLoading ||
                this.sendFormIsLoading ||
                this.$store.getters['estates/isLoading'];
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
        backHandler() {
            this.$store.state.status.currentStepIndex = Math.max(this.currentStepIndex - 1, 0);
        },
        getOrCreateFormData() {
            this.$store.dispatch('status/getFormData', {
                id: this.givenEstate,
                onSuccess: () => {
                    this.$store.state.status.estateId = this.givenEstate;
                },
                onError: () => {
                    this.$store.dispatch('status/createFormData', {
                        id: this.givenEstate,
                        onSuccess: () => {
                            this.getOrCreateFormData();
                        },
                    });
                },
            });
        },
        onLastStepComplete() {
            this.isLastStepCompleted = true;
        },
        onFormSend() {
            this.isFormSent = true;
        },
    },
    beforeMount() {
        this.$store.commit('status/reset');
        if (this.estate) {
            this.$store.state.status.givenEstate = this.estate.id;
        }
        this.getOrCreateFormData();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form {
    .modal();

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    padding: 24px;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        display: flex;
        justify-content: center;
        align-items: center;

        background: @color-gray-lightest;
        &-container {
            width: 100px;
        }
    }
    &__back {
        position: absolute;
        top: 24px;
        left: 24px;
        z-index: 1;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        cursor: pointer;
        &-icon {
            font-size: 1.6rem;
        }
        &-title {
            .typography-body();

            text-decoration: underline;
        }
    }
    &__content {
        flex: 0 0 auto;
        max-width: 850px;
        width: 100%;
        margin: 0 auto auto;
        padding-bottom: 32px;
    }
    &__botline {
        flex: 0 0 auto;
        padding-top: 100px;
        margin: auto auto 0;
        &-support {
            .typography-body();

            padding: 4px 12px;
            border-radius: 20px;

            background-color: @color-gray-light;
        }
    }
}
</style>
