import { render, staticRenderFns } from "./illustration.vue?vue&type=template&id=7f6c43f8&scoped=true"
import script from "./illustration.vue?vue&type=script&lang=js"
export * from "./illustration.vue?vue&type=script&lang=js"
import style0 from "./illustration.vue?vue&type=style&index=0&id=7f6c43f8&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f6c43f8",
  null
  
)

export default component.exports