var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-table",class:[
        `_theme_${_vm.theme}`,
        { _autosize: !_vm.sizesProvided }
    ],attrs:{"role":"table"}},[(_vm.rows.length > 0)?[_c('div',{staticClass:"ui-table__container"},[(_vm.columns && _vm.columns.length > 0)?_c('div',{staticClass:"ui-table__header",style:({
                    paddingLeft: _vm.layout && _vm.layout.rowPaddingLeft,
                    paddingRight: _vm.layout && _vm.layout.rowPaddingRight,
                })},_vm._l((_vm.columns),function(column,index){return _c('div',{key:index,staticClass:"ui-table__column",class:{ _wide: column.wide },style:({
                        minWidth: column.minWidth || (_vm.layout && _vm.layout.minWidth),
                        flex: '1 1 ' + column.width,
                        ...column.style,
                    })},[_c('div',{staticClass:"ui-table__header-cell"},[_c('span',{domProps:{"textContent":_vm._s(column.title)}}),(column.sortable)?_c('span',{staticClass:"ui-table__header-cell-icon"},[_c('span',{staticClass:"icon icon-sort-desc"})]):_vm._e()])])}),0):_vm._e(),_c('div',{staticClass:"ui-table__body"},_vm._l((_vm.rows),function(row,index){return _c('div',{key:index,staticClass:"ui-table__body-row",style:({
                        paddingLeft: _vm.layout && _vm.layout.rowPaddingLeft,
                        paddingRight: _vm.layout && _vm.layout.rowPaddingRight,
                        marginBottom: _vm.layout && _vm.layout.rowMarginBottom,
                    })},_vm._l((row),function(cell,index){return _c('div',{key:index,staticClass:"ui-table__column",class:{
                            _wide: _vm.columns && _vm.columns[index] && _vm.columns[index].wide,
                            _fixed: _vm.columns && _vm.columns[index] && _vm.columns[index].fixed,
                        },style:({
                            minWidth: (_vm.columns && _vm.columns[index] && _vm.columns[index].minWidth) || (_vm.layout && _vm.layout.minWidth),
                            flex: '1 1 ' + (_vm.columns && _vm.columns[index] && _vm.columns[index].width),
                            ...(_vm.columns && _vm.columns[index] && _vm.columns[index].style)
                        })},[(cell.type === 'component')?[_c(cell.component,_vm._b({tag:"component"},'component',cell.props,false))]:[_c('span',{domProps:{"textContent":_vm._s(cell.props.text)}})]],2)}),0)}),0)])]:[_c('div',{staticClass:"ui-table__empty",domProps:{"textContent":_vm._s('Записей не найдено')}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }