var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-form-field",class:{ _for_file: _vm.model.type === 'file' || _vm.model.type === 'image' }},[(_vm.model.type !== 'checkbox')?[(_vm.model.labelIsHtml)?_c('label',{staticClass:"ui-form-field__title",class:{
                _empty: _vm.isEmpty,
                _invalid: _vm.isInvalid,
                _focused: _vm.isFocused,
                _disabled: _vm.model.isDisabled,
                _large: _vm.model.largeLabel || _vm.model.type === 'radio-group',
            }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.model.label)}}),(_vm.model.labelHint)?_c('ui-hint',_vm._b({},'ui-hint',_vm.model.labelHint,false)):_vm._e()],1):(_vm.labelContainsBBCode)?_c('label',{staticClass:"ui-form-field__title",class:{
                _empty: _vm.isEmpty,
                _invalid: _vm.isInvalid,
                _focused: _vm.isFocused,
                _disabled: _vm.model.isDisabled,
                _large: _vm.model.largeLabel || _vm.model.type === 'radio-group',
            }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.parsedLabel)}}),(_vm.model.labelHint)?_c('ui-hint',_vm._b({},'ui-hint',_vm.model.labelHint,false)):_vm._e()],1):_c('label',{staticClass:"ui-form-field__title",class:{
                _empty: _vm.isEmpty,
                _invalid: _vm.isInvalid,
                _focused: _vm.isFocused,
                _disabled: _vm.model.isDisabled,
                _large: _vm.model.largeLabel || _vm.model.type === 'radio-group',
            }},[_vm._v(" "+_vm._s(_vm.model.label + (_vm.isRequired ? '*' : ''))+" "),(_vm.model.labelHint)?_c('ui-hint',_vm._b({},'ui-hint',_vm.model.labelHint,false)):_vm._e()],1)]:_vm._e(),(_vm.model.labels && _vm.model.labels.length > 0)?_c('div',{staticClass:"ui-form-field__labels"},_vm._l((_vm.model.labels),function(label,index){return _c('div',{key:index,staticClass:"ui-form-field__labels-item"},[_c('ui-label',_vm._b({},'ui-label',{ isInForm: true, ...label },false))],1)}),0):_vm._e(),_c('div',{staticClass:"ui-form-field__input"},[(_vm.model.prefix)?_c('div',{staticClass:"ui-form-field__input-prefix",domProps:{"textContent":_vm._s(_vm.model.prefix)}}):_vm._e(),_c('div',{staticClass:"ui-form-field__input-field"},[_c(_vm.input,_vm._g({tag:"component",attrs:{"model":_vm.model,"value":_vm.value,"is-invalid":_vm.isInvalid}},_vm.getHandler(_vm.model.type)))],1)]),_c('div',{staticClass:"ui-form-field__botline"},[(_vm.error && _vm.isInvalid)?_c('div',{staticClass:"ui-form-field__botline-error",domProps:{"textContent":_vm._s(_vm.error)}}):_vm._e(),(_vm.hint)?_c('div',{staticClass:"ui-form-field__botline-hint"},[(_vm.hint === 'recovery')?[_c('a',{staticClass:"ui-form-field__botline-hint-link",attrs:{"href":_vm.passwordResetHref},domProps:{"textContent":_vm._s('Забыли пароль?')}})]:_vm._e()],2):_vm._e()]),(_vm.model.warning)?_c('div',{staticClass:"ui-form-field__warning"},[_c('div',{staticClass:"ui-form-field__warning-icon"},[_c('icon',{attrs:{"name":"attention","width":"auto","height":"1em"}})],1),_c('div',{staticClass:"ui-form-field__warning-text",domProps:{"textContent":_vm._s(_vm.model.warning)}})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }