<template>
    <div class="modal-status-form-auth">
        <div class="modal-status-form-auth__title"
            v-text="'Авторизация'"
        />
        <div class="modal-status-form-auth__description">
            Для{{'\xa0'}}работы с{{'\xa0'}}формой необходимо пройти авторизацию.
            <br>
            Вы{{'\xa0'}}будете перенаправлены на{{'\xa0'}}сайт сервиса авторизации.
            <br>
            После прохождения авторизации вы{{'\xa0'}}сможете вернуться к{{'\xa0'}}заполнениию формы.
        </div>
        <div class="modal-status-form-auth__button"
            v-if="getAuthUrlResult"
            >
            <ui-button
                v-bind:href="getAuthUrlResult.url"
                >
                Пройти авторизацию
            </ui-button>
        </div>
        <template v-if="getAuthUrlError">
            <div class="modal-status-form-auth__description _error"
                v-text="'Ошибка запроса ссылки авторизации кабинета акционера'"
            />
            <div class="modal-status-form-auth__button">
                <ui-button
                    v-on:click="getLink"
                    >
                    Повторить попытку
                </ui-button>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-status-form-auth',
    computed: {
        ...mapState('status', [
            'getAuthUrlError',
            'getAuthUrlResult',
        ]),
    },
    methods: {
        getLink() {
            this.$store.dispatch('status/getAuthUrl');
        },
    },
    beforeMount() {
        this.$store.state.status.getAuthUrlResult = null;
        this.getLink();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-auth {
    &__title {
        .typography-heading();

        margin-bottom: 24px;

        text-align: center;
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;

        text-align: center;
        &._error {
            color: @color-accent-negative;
        }
    }
    &__button {
        width: fit-content;
        margin: 0 auto;
    }
}
</style>
