<template>
    <div class="page-admin-estates-item-info-general">
        <div class="page-admin-estates-item-info-general__top">
            <div class="page-admin-estates-item-info-general__top-block">
                <ui-form
                    v-bind:model="fieldsTop"
                    v-bind:validation="validation"
                    v-bind:submit-handler="submitHandler"
                    v-on:update="updateFormData"
                />
            </div>
            <div class="page-admin-estates-item-info-general__top-block">
                <ui-form
                    v-bind:model="fieldsImage"
                    v-bind:validation="validation"
                    v-bind:submit-handler="submitHandler"
                    v-on:update="updateFormData"
                />
            </div>
        </div>
        <div class="page-admin-estates-item-info-general__bottom">
            <ui-form
                v-bind:model="fieldsBottom"
                v-bind:validation="validation"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
                gap="wide"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-admin-estates-item-info-general',
    props: [ 'fields', 'validation' ],
    computed: {
        fieldsTop() {
            const fieldNames = [
                'title',
                'type',
                'address',
                'description',
            ];
            return this.getFieldsByNames(fieldNames);
        },
        fieldsBottom() {
            const fieldNames = [
                'totalValue',
                // 'target',
                // 'currentDividendProfitability',
                'targetDividendProfitability',
                'yearPercent',
                'maxLoanTerm',
                'capRate',
                'totalSquare',
                'rentableSquare',
                'occupancy',
                'tradeMinInvestmentAmount',
                'initialBalance',
                'annualIndexing',
                'shareCapital',
            ];
            return this.getFieldsByNames(fieldNames);
        },
        fieldsImage() {
            return { image: this.fields.image };
        },
    },
    methods: {
        submitHandler() {
            this.$emit('submit');
        },
        updateFormData(newData) {
            this.$emit('update', newData);
        },
        getFieldsByNames(names = []) {
            const result = {};
            names.forEach(x => {
                if (this.fields[x] !== undefined) {
                    result[x] = this.fields[x];
                }
            });
            return result;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-info-general {
    &__top {
        display: flex;
        margin: 0 -28px 80px;
        &-block {
            flex: 0 0 50%;
            padding: 0 28px;
        }
    }
    &__bottom {
    }
    @media @media-sm-down {
        &__top {
            display: block;
            margin: 0 0 40px;
            &-block {
                width: 100%;
                padding: 0;
            }
        }
    }
}
</style>
