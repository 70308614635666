import { render, staticRenderFns } from "./digital.vue?vue&type=template&id=34a5fca4&scoped=true"
import script from "./digital.vue?vue&type=script&lang=js"
export * from "./digital.vue?vue&type=script&lang=js"
import style0 from "./digital.vue?vue&type=style&index=0&id=34a5fca4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a5fca4",
  null
  
)

export default component.exports