var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.component,{tag:"component",staticClass:"ui-trigger",class:[
        `_theme_${_vm.theme}`,
        {
            _underlined: _vm.isUnderlined,
            _inlined: _vm.isInlined,
            _loading: _vm.isLoading,
            _disabled: _vm.isDisabled,
            _inherited: _vm.isInherited,
            _breakable: _vm.isBreakable,
            _uppercase: _vm.isUppercase,
            [`_size_${_vm.size}`]: _vm.size,
        },
    ],attrs:{"href":_vm.href ? _vm.href : null,"target":_vm.href ? '_blank' : null,"type":_vm.href ? null : _vm.type,"disabled":_vm.isDisabled || _vm.isLoading},on:{"click":function($event){(_vm.href || _vm.isDisabled || _vm.isLoading) ? null : _vm.$emit('click')}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading)?_c('div',{staticClass:"ui-trigger__loader"},[_c('ui-loader',{attrs:{"is-mini":""}})],1):_vm._e()]),(_vm.iconLeft)?_c('div',{staticClass:"ui-trigger__icon _left"},[_c('icon',{attrs:{"name":_vm.iconLeft}})],1):_vm._e(),(_vm.text !== null)?_c('div',{staticClass:"ui-trigger__text",domProps:{"textContent":_vm._s(_vm.text)}}):(_vm.$slots.default)?_c('div',{staticClass:"ui-trigger__text"},[_vm._t("default")],2):_vm._e(),(_vm.iconRight)?_c('div',{staticClass:"ui-trigger__icon _right"},[_c('icon',{attrs:{"name":_vm.iconRight}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }