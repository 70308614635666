var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"ui-input-base-select",class:{
        _active: _vm.isActive,
        _focused: _vm.isFocused,
        _invalid: _vm.isInvalid,
        _disabled: _vm.isDisabled,
        _list: _vm.isList,
    },attrs:{"tabindex":"0"},on:{"focus":_vm.customFocusHandler,"blur":_vm.customBlurHandler,"keydown":_vm.keydownHandler}},[_c('div',{staticClass:"ui-input-base-select__custom"},[_c('div',{staticClass:"ui-input-base-select__custom-trigger",on:{"click":_vm.toggle}},[(_vm.icon)?_c('div',{staticClass:"ui-input-base-select__custom-trigger-icon"},[_c('icon',{attrs:{"name":_vm.icon}})],1):_vm._e(),_c('div',{staticClass:"ui-input-base-select__custom-trigger-text",domProps:{"textContent":_vm._s(_vm.triggerText)}}),_c('div',{staticClass:"ui-input-base-select__custom-trigger-triangle"},[_c('icon',{attrs:{"name":"triangle"}})],1)]),_c('div',{staticClass:"ui-input-base-select__custom-options"},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"ui-input-base-select__custom-options-item",class:{ _active: option.value === _vm.value },attrs:{"value":option.value,"disabled":option.isDisabled},domProps:{"textContent":_vm._s(option.text)},on:{"click":function($event){option.isDisabled ? null : _vm.customOptionClickHandler(option.value)}}})}),0)]),_c('div',{staticClass:"ui-input-base-select__native"},[_c('select',{ref:"input",staticClass:"ui-input-base-select__native-select",attrs:{"placeholder":_vm.placeholder,"autofocus":_vm.isAutofocus,"disabled":_vm.isDisabled,"tabindex":"-1"},domProps:{"value":_vm.value},on:{"change":_vm.changeHandler,"focus":_vm.focusHandler,"blur":_vm.blurHandler}},[(_vm.placeholder !== undefined)?_c('option',{attrs:{"text":_vm.placeholder,"value":"","disabled":""}}):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('option',{key:index,domProps:{"value":option.value,"textContent":_vm._s(option.text)}})})],2),_c('div',{staticClass:"ui-input-base-select__native-triangle"},[_c('icon',{attrs:{"name":"triangle"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }