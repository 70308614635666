<template>
    <div class="page-admin-estates-item-requests-actions__content">
            <div class="page-admin-estates-item-requests-actions__content-buttons"
                v-if="getEstateInfoResult"
                >
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="status === IssueStatus.COLLECTED"
                    >
                    <ui-button
                        v-on:click="startCollecting"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:disabled="isAnyInvalid || isAnotherNewIssue"
                        text="Возобновить сбор"
                        theme="secondary-1"
                        size="sm"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="isFilled && status === IssueStatus.NEW"
                    >
                    <ui-button
                        v-on:click="stopCollecting"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:disabled="isAnyInvalid"
                        :text="`Остановить сбор в выпуске ${issue.index + 1}`"
                        theme="primary"
                        size="sm"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="isFilled && status === IssueStatus.COLLECTED"
                    >
                    <ui-button
                        v-on:click="generateInvestmentOfferDocuments"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        text="Подготовить инвест предложение"
                        theme="primary"
                        size="sm"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="status === IssueStatus.DOCUMENTS_IN_PROGRESS"
                    >
                    <ui-button
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        disabled
                        text="Документы генерируются..."
                        theme="primary"
                        size="sm"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="status === IssueStatus.DOCUMENTS_READY"
                    >
                    <ui-button
                        v-on:click="issueInvestOffer"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        text="Отправить инвест предложение"
                        theme="primary"
                        size="sm"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="status === IssueStatus.WAIT_SIGN"
                    >
                    <ui-button
                        v-on:click="cancelExpiredInvestmentRequests"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:is-disabled="cancelExpiredInvestmentRequestsIsLoading"
                        text="Отменить неподписанные заявки"
                        theme="secondary-2"
                        size="sm"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="status === IssueStatus.SIGNED"
                    >
                    <ui-button
                        v-on:click="transferMoney"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:is-disabled="transferMoneyIsLoading"
                        text="Перевести деньги на счет АО"
                        theme="secondary-3"
                        size="sm"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="status === IssueStatus.LOAN_PROCESSING"
                    >
                    <ui-button
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        disabled
                        text="Идет перевод средств..."
                        theme="primary"
                        size="sm"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="status === IssueStatus.LOAN_ISSUED"
                    >
                    <ui-button
                        v-on:click="resolveOffer"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:is-disabled="convertEstateToSharesIsLoading"
                        text="Выпустить акции"
                        theme="secondary-3"
                        size="sm"
                    />
                </div>

                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="isFilled && status === IssueStatus.NEW"
                    >
                    <ui-button
                        v-on:click="createInvestment"
                        theme="transparent"
                        size="sm"
                        text="Добавить заявку"
                    />
                </div>
                <div class="page-admin-estates-item-requests-actions__content-buttons-item"
                    v-if="isDownloadable"
                    >
                    <ui-button
                        v-on:click="download"
                        v-bind:icon-left="'download'"
                        theme="transparent"
                        size="sm"
                        text="Скачать все документы выпуска"
                    />
                </div>
            </div>
        </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';
import { IssueStatus } from '~/constants';

export default {
    name: 'page-admin-estates-item-requests-actions',
    props: {
        /**
         * @type {IssueDashboardTransformed}
         */
        issue: {
            type: Object,
        },
        /**
         * @type {InvestmentRoundDashboardTransformed}
         */
        round: {
            type: Object,
        },
        estateId: {
            type: Number,
        },
        isAnyInvalid: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        cancelLoanRequired: false,
    }),
    computed: {
        ...mapState('admin/estates', [
            'getEstateRequestsIsLoading',
            'getEstateRequestsResult',
            'getEstateRequestsError',

            'updateEstateInfoIsInvalid',
            'updateEstateInfoIsLoading',

            'getEstateInfoIsLoading',
            'getEstateInfoError',
            'getEstateInfoResult',

            'getEstateRequestsIsLoading',
            'getEstateRequestsResult',
            'getEstateRequestsError',

            'getEstateRoundsIsLoading',
            'getEstateRoundsError',
            'getEstateRoundsResult',
        ]),
        ...mapState('admin/investments', [
            'cancelExpiredInvestmentRequestsIsLoading',
            'cancelExpiredInvestmentRequestsError',
            'cancelExpiredInvestmentRequestsResult',

            'transferMoneyIsLoading',
            'transferMoneyError',
            'transferMoneyResult',

            'convertEstateToSharesIsLoading',
            'convertEstateToSharesError',
            'convertEstateToSharesResult',
        ]),
        ...mapState('admin/loans', [
            'getLoanIsLoading',
            'getLoanError',
            'getLoanResult',

            'cancelLoanIsLoading',
            'cancelLoanError',
            'cancelLoanResult',
        ]),
        IssueStatus() {
            return IssueStatus;
        },
        balance() {
            return this.round.balance;
        },
        balanceSigned() {
            return this.round.balanceSigned;
        },
        target() {
            return this.round.target;
        },
        progress() {
            if (this.round.isActive) {
                if (this.issue.displayMode === IssueStatus._COLLECTING) {
                    return Math.min(this.round.balance / this.round.target * 100, 100);
                } else {
                    return Math.min(this.round.balanceSigned / this.round.balance * 100, 100);
                }
            }
            return Math.min(this.balance / this.target * 100, 100);
        },
        isFilled() {
            return true || this.progress >= 100;
        },
        isSignsFilled() {
            return this.balanceSigned >= this.balance && this.balanceSigned > 0;
        },
        status() {
            return this.issue?.status;
        },
        isMobile() {
            return window.innerWidth < 768;
        },
        isAnotherNewIssue() {
            return !!this.round?.issues?.find(i => i.status === IssueStatus.NEW && i.id !== this.issue.id);
        },
        isDownloadable() {
            return this.issue.investmentAgreementCount > 0 ||
                this.issue.investmentProposalCount > 0 ||
                this.issue.transferOrderCount > 0 ||
                this.issue.investmentAcceptCount > 0;
        },
    },
    methods: {
        getFormattedNumber(number) {
            return utils.formats.formatNumberWithSpaces(number);
        },
        stopCollecting() {
            this.$store.dispatch('admin/estates/updateIssueInfo', { status: IssueStatus.COLLECTED, id: this.issue.id, isUpdateInfoNeeded: true });
        },
        startCollecting() {
            this.$store.dispatch('admin/estates/updateIssueInfo', { status: IssueStatus.NEW, id: this.issue.id, isUpdateInfoNeeded: true });
        },
        cancelExpiredInvestmentRequests() {
            this.$store.commit('modals/push', {
                name: 'cancel-expired-investment-requests',
                props: {
                    estate: this.getEstateInfoResult,
                    requests: this.issue.requests,
                    issue: this.issue,
                },
            });
        },
        generateInvestmentOfferDocuments() {
            this.$store.commit('modals/push', {
                name: 'prepare-invest-offer',
                props: {
                    estate: this.getEstateInfoResult,
                    issue: this.issue,
                },
            });
        },
        issueInvestOffer() {
            this.$store.commit('modals/push', {
                name: 'issue-invest-offer',
                props: {
                    estate: this.getEstateInfoResult,
                    issue: this.issue,
                },
            });
        },
        transferMoney() {
            // transfer money to bank for loan issue
            this.$store.dispatch('admin/investments/transferMoney', { id: this.issue.id });
        },
        resolveOffer() {
            // direct shares issue
            this.$store.dispatch('admin/investments/convertEstateToShares', { id: this.issue.id });
        },
        createInvestment() {
            this.$store.commit('modals/push', {
                name: 'create-investment-request',
                props: {
                    round: this.round,
                    issue: this.issue,
                    estateId: this.estateId,
                    onComplete: () => {
                        // this.$emit('request-reload');
                    },
                },
            });
        },
        download() {
            if (!this.isDownloadable) {
                return;
            }
            this.$store.commit('modals/push', {
                name: 'download',
                props: {
                    mode: 'investment-round',
                    dataToDownload: {
                        round: this.issue.index + 1,
                        estate: this.estateId,
                        investmentAgreementCount: this.issue.investmentAgreementCount,
                        investmentProposalCount: this.issue.investmentProposalCount,
                        transferOrderCount: this.issue.transferOrderCount,
                        investmentAcceptCount: this.issue.investmentAcceptCount,
                    },
                },
            });
        },
    },
    watch: {
        updateEstateInfoIsLoading(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
            }
        },
        getLoanResult(newVal) {
            if (this.cancelLoanRequired && newVal && this.getLoanResult !== null) {
                this.$store.dispatch('admin/loans/cancelLoan', { id: this.getLoanResult.id });
                this.cancelLoanRequired = false;
            }
        },
        cancelLoanResult(newVal) {
            if (newVal) {
                if (this.cancelLoanRequired) {
                    this.cancelLoanRequired = false;
                }
                if (this.getEstateInfoResult) {
                    this.$store.dispatch('admin/estates/getEstateInfo', { id: this.getEstateInfoResult.id });
                    this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
                }
            }
        },
        cancelExpiredInvestmentRequestsResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
            }
        },
        transferMoneyResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
            }
        },
        convertEstateToSharesResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
                this.$store.commit('modals/push', {
                    name: 'generic',
                    props: {
                        title: 'Акции успешно выпущены!',
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-requests-actions {
    background-color: @color-gray-lighter;
    &__content {
        .container();

        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 0 15px 0;
        &-balance {
            flex: 0 0 auto;
            &-item {
                display: flex;
                align-items: baseline;
                &-title {
                    .typography-heading();

                    margin-right: 5px;
                }
                &-value {
                    .typography-caption();
                    &-accent {
                        .typography-body();
                    }
                }
            }
        }
        &-loan {
            flex: 0 0 auto;
            display: flex;
            align-items: baseline;
            &-title {
                .typography-heading();

                margin-right: 5px;
            }
            &-value {
                .typography-body();
            }
        }
        &-text {
            .typography-body();

            flex: 1 1 auto;
            margin: auto 24px;

            text-align: right;
            &._error {
                color: @color-accent-negative;
            }
        }
        &-buttons {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: -12px;
            padding-left: 0;
            &-item {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 12px;
                &-text {
                    .typography-body();

                    flex: 1 1 auto;
                    margin: auto 24px;

                    text-align: right;
                    &._error {
                        color: @color-accent-negative;
                    }
                }
            }
        }
    }
    @media @media-sm-down {
        &__content {
            display: block;
            padding-top: 24px;
            padding-bottom: 24px;

            text-align: center;
            &-balance {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 16px;
                &-item {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
            &-loan {
                display: block;
                margin-bottom: 16px;
                &-title {
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }
            &-text {
                margin-bottom: 16px;

                text-align: center;
            }
            &-buttons {
                padding-left: 0;
                margin: -8px;
                flex-direction: column;
                justify-content: center;
                &-item {
                    padding: 8px;
                }
            }
        }
    }
}
</style>
