<template>
    <div class="modal-create-investment-request">
        <div class="modal-create-investment-request__title">
            Добавление заявки
        </div>
        <div class="modal-create-investment-request__description" v-if="isUserFound">
            <span class="modal-create-investment-request__heading">Пользователь найден:</span>
            {{user.document_full_name}} {{user.phone}}
        </div>
        <div class="modal-create-investment-request__heading">
            {{subtitle}}
        </div>
        <div class="modal-create-investment-request__form">
            <template v-if="!isUserFound">
                <ui-form
                    v-bind:model="fields"
                    v-bind:validation="$v"
                    v-bind:submit-handler="submitSearchHandler"
                    v-on:update="updateUserSearchFormData"
                />
            </template>
            <template v-if="isUserFound">
                <ui-form
                    v-bind:model="createFields"
                    v-bind:validation="$v"
                    v-bind:validation-path="'createFields'"
                    v-bind:submit-handler="submitCreateHandler"
                    v-on:update="updateCreateInvestmentRequestFormData"
                />
            </template>
            <div class="modal-create-investment-request__form-error"
                v-if="error"
                v-text="error"
            />
        </div>
        <div class="modal-create-investment-request__description" v-if="isUserFound">
            <span class="modal-create-investment-request__heading">Общая сумма:</span>
            {{amount}} рублей
        </div>
        <div class="modal-edit-investment-request__button">
            <ui-button
                v-bind:disabled="$v.fields.$dirty && $v.fields.$invalid"
                v-on:click="submitSearchHandler"
                theme="primary"
                v-if="!isUserFound"
                >
                Найти
            </ui-button>
            <ui-button
                v-bind:disabled="$v.createFields.$dirty && $v.createFields.$invalid"
                v-on:click="submitCreateHandler"
                theme="primary"
                v-if="isUserFound"
                >
                Создать заявку
            </ui-button>
        </div>
    </div>
</template>

<script>
// import { mapState } from 'vuex';
import forms from '~/forms';
import { mainClient } from '~/network';

export default {
    name: 'modal-create-investment-request',
    props: {
        /**
         * @type {IssueDashboardTransformed}
         */
        issue: {
            type: Object,
        },
        estateId: {
            type: [ Number, String ],
        },
        onComplete: {
            type: Function,
            default: null,
        },
    },
    mounted() {
        this.createFields.stockPrice.value = this.issue.sharePrice;
    },
    data: () => ({
        user: {
            id: null,
            phone: null,
            document_full_name: null,
        },
        fields: {
            phone: { ...forms.fields.phone },
        },
        error: null,
        createFields: {
            stockPrice: forms.fields.admin.investOffer.stockPrice,
            shareQuantity: forms.fields.admin.investOffer.shareQuantity,
        },
    }),
    validations: {
        fields: {
            phone: { ...forms.validations.phone },
        },
        createFields: {
            stockPrice: { ...forms.validations.genericRequired },
            shareQuantity: { ...forms.validations.genericRequired },
        },
    },
    computed: {
        subtitle() {
            if (!this.user.id) {
                return '1/2: Введите номер телефона пользователя';
            }
            return '2/2: Введите стоимость и количество акций';
        },
        isUserFound() {
            return !!this.user.id;
        },
        amount() {
            if (!this.$v.createFields.$invalid) {
                return this.createFields.shareQuantity.value * this.createFields.stockPrice.value;
            }
            return 0;
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
        updateUserSearchFormData(data) {
            this.error = null;
            // this.$v.$touch();
            this.fields = data;
        },
        updateCreateInvestmentRequestFormData(data) {
            this.$v.createFields.$touch();
            this.createFields = data;
        },
        async submitSearchHandler() {
            this.$v.fields.$touch();
            if (this.$v.fields.$invalid) {
                return;
            }

            const { data } = await mainClient.admin.requests.searchByPhone({
                phone: this.fields.phone.value,
            });

            if (!data[0]) {
                this.error = 'Пользователь не найден';
            } else {
                this.user = data[0];
            }
        },
        async submitCreateHandler() {
            this.error = null;
            this.$v.createFields.$touch();
            const { error } = await mainClient.estates.requests.createEstateInvestRequest({
                estateId: this.estateId,
                issueId: this.issue.id,
                userId: this.user.id,
                shareQuantity: this.createFields.shareQuantity.value,
            });
            if (!error) {
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
                this.close();
            } else {
                this.error = '';
                for (const errorKey in error) {
                    this.error += `${error[errorKey]?.toString()}.`;
                } // TODO double code
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-create-investment-request {
    .modal();

    display: flex;
    flex-direction: column;
    gap: 24px;

    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 75%);
        &-container {
            width: 50px;
            padding-top: 8px;
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__heading {
        .typography-heading();
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;
        &-date {
            color: @color-gray-main;
        }
    }
    &__form {
        &-error {
            .typography-body();

            margin-top: 4px;

            color: @color-accent-negative;
        }
    }
    &__button {
        width: 206px;
        margin: 0 auto 24px;
    }
    &__cancel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        margin: 0 auto;

        color: @color-accent-negative;

        cursor: pointer;
        &-icon {
            margin-right: 10px;

            font-size: 2rem;
        }
        &-text {
            .typography-heading();
        }
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
