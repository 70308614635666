<template>
    <div class="modal-edit-investment-request">
        <transition name="fade">
            <div class="modal-edit-investment-request__loader"
                v-if="editEstateInvestRequestIsLoading || cancelEstateInvestRequestIsLoading"
                >
                <div class="modal-edit-investment-request__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-edit-investment-request__title">
            Редактирование заявки
        </div>
        <div class="modal-edit-investment-request__description">
            <span class="modal-edit-investment-request__description-date" v-text="request.created" /><br/>
            {{ request.applicant.fullName }}<br/>
            {{ request.applicant.phone }}<br/>
            Счет: {{ request.account }}<br/>
        </div>
        <div class="modal-edit-investment-request__form">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
            <div class="modal-edit-investment-request__form-error"
                v-if="error"
                v-text="error"
            />
        </div>
        <div class="modal-edit-investment-request__button">
            <ui-button
                v-bind:disabled="$v.$dirty && $v.$invalid"
                v-on:click="submitHandler"
                theme="primary"
                >
                Сохранить
            </ui-button>
        </div>
        <div class="modal-edit-investment-request__cancel"
            v-on:click="cancel"
            >
            <div class="modal-edit-investment-request__cancel-icon">
                <icon name="cancel" />
            </div>
            <div class="modal-edit-investment-request__cancel-text">
                Отменить заявку
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'modal-edit-investment-request',
    props: {
        /**
         * @type DashboardInvestmentRequestTransformed
         */
        request: {
            type: Object,
        },
        estateId: {
            type: [ Number, String ],
        },
        onComplete: {
            type: Function,
            default: null,
        },
    },

    data: () => ({
        fields: {
            shareQuantity: forms.fields.admin.investOffer.shareQuantity,
        },
    }),
    validations: {
        fields: {
            shareQuantity: { ...forms.validations.admin.investOffer.investmentRequestAmount },
        },
    },
    computed: {
        ...mapState('admin/estates', [
            'editEstateInvestRequestIsLoading',
            'editEstateInvestRequestResult',
            'editEstateInvestRequestError',
            'cancelEstateInvestRequestIsLoading',
            'cancelEstateInvestRequestResult',
            'cancelEstateInvestRequestError',
        ]),
        error() {
            if (this.editEstateInvestRequestError) {
                return `Сумму этой заявки нельзя сделать больше ${utils.formats.formatNumberWithSpaces(this.request.reservedBalance)} ₽. Пожалуйста, проверьте введенные данные.`;
            }
            if (this.cancelEstateInvestRequestError) {
                return 'Ошибка! Обратитесь к администратору для отмены заявки';
            }
            return null;
        },
    },
    methods: {
        updateFormData(data) {
            this.fields = data;
        },
        close() {
            this.$store.commit('modals/pop');
        },
        submitHandler() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch('admin/estates/editEstateInvestRequest', {
                estateId: this.estateId,
                requestId: this.request.id,
                share_quantity: this.fields.shareQuantity.value,
                onSuccess: this.onComplete,
            });
        },
        cancel() {
            this.$store.dispatch('admin/estates/cancelEstateInvestRequest', {
                estateId: this.estateId,
                requestId: this.request.id,
                onSuccess: this.onComplete,
            });
        },
    },
    beforeMount() {
        this.$store.commit('admin/estates/resetCancelEstateInvestRequest');
        this.$store.commit('admin/estates/resetEditEstateInvestRequest');
        this.fields.shareQuantity.value = this.request.amount / this.request.sharePrice;
    },
    watch: {
        editEstateInvestRequestResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateRequests', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.commit('modals/pop');
            }
        },
        cancelEstateInvestRequestResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateRequests', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.commit('modals/pop');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-edit-investment-request {
    .modal();

    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 75%);
        &-container {
            width: 50px;
            padding-top: 8px;
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;
        &-date {
            color: @color-gray-main;
        }
    }
    &__form {
        margin-bottom: 40px;
        &-error {
            .typography-body();

            margin-top: 4px;

            color: @color-accent-negative;
        }
    }
    &__button {
        width: 206px;
        margin: 0 auto 24px;
    }
    &__cancel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        margin: 0 auto;

        color: @color-accent-negative;

        cursor: pointer;
        &-icon {
            margin-right: 10px;

            font-size: 2rem;
        }
        &-text {
            .typography-heading();
        }
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
