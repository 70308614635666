var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-admin-estates-list"},[_c('div',{staticClass:"page-admin-estates-list__headline"},[_c('div',{staticClass:"page-admin-estates-list__headline-container"},[_c('h1',{staticClass:"page-admin-estates-list__headline-title"},[_vm._v(" Объекты инвестирования ")]),_c('div',{staticClass:"page-admin-estates-list__headline-create"},[_c('ui-button',{attrs:{"theme":'primary',"icon-left":"plus"},on:{"click":_vm.createEstateHandler}},[_vm._v(" Добавить объект ")])],1),_c('div',{staticClass:"page-admin-estates-list__headline-create _mobile"},[_c('ui-button',{attrs:{"theme":'primary'},on:{"click":_vm.createEstateHandler}},[_c('icon',{attrs:{"name":"plus"}})],1)],1)])]),_c('section',{staticClass:"page-admin-estates-list__list"},[_c('div',{staticClass:"page-admin-estates-list__list-content"},_vm._l((_vm.accumulatedItems),function(item,index){return _c('div',{key:index,staticClass:"page-admin-estates-list__list-content-item"},[_c('common-card-estate',{attrs:{"item":item,"resizes":{
                        1920: 24,
                        1599: 35,
                        1279: 30,
                        1023: 46,
                        767: 100,
                    },"button-text":"Редактировать"}})],1)}),0),(_vm.isLoading)?_c('div',{staticClass:"page-admin-estates-list__list-loader"},[_c('ui-loader',{attrs:{"fixed":50,"centered":true}})],1):_vm._e(),(!_vm.isLoading && _vm.pagingTotal > _vm.pagingCurrent)?_c('div',{staticClass:"page-admin-estates-list__list-buttons"},[_c('div',{staticClass:"page-admin-estates-list__list-buttons-item",on:{"click":_vm.getMore}},[_c('ui-button',[_vm._v(" Показать еще ")])],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }