var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-input-base-text"},[_c('input',_vm._g({ref:"input",staticClass:"ui-input-base-text__input",class:{
            _no_placeholder: _vm.isFocused && _vm.value.toString().length > 0,
            _focused: _vm.isFocused,
            _invalid: _vm.isInvalid,
            _disabled: _vm.isDisabled,
            _with_units: _vm.units !== null,
            _filled: !!_vm.value,
        },attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"autofocus":_vm.isAutofocus,"disabled":_vm.isDisabled,"tabindex":"0"},domProps:{"value":_vm.value},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.inputHandler.apply(null, arguments)}}},_vm.handler)),(_vm.units !== null)?_c('div',{staticClass:"ui-input-base-text__units",domProps:{"textContent":_vm._s(_vm.units)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }