<template>
    <div class="modal-prepare-invest-offer">
        <transition name="fade">
            <div class="modal-prepare-invest-offer__loader"
                v-if="isLoading"
                >
                <div class="modal-prepare-invest-offer__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <template v-if="step === 'form'">
            <div class="modal-prepare-invest-offer__title">
                Подготовка инвестиционного предложения
            </div>
            <div class="modal-prepare-invest-offer__subtitle"
                v-text="estate.title"
            />
            <div class="modal-prepare-invest-offer__price">
                <modal-prepare-invest-offer-price
                    v-bind:issue="issue"
                    v-on:recalculation-requirement-change="recalculationHandler"
                    v-on:calculated-price-change="setPrice"
                />
            </div>
            <div class="modal-prepare-invest-offer__form"
                v-if="calculateSharesResult"
                >
                <modal-prepare-invest-offer-form
                    v-bind:estate="estate"
                    v-bind:issue="issue"
                    v-bind:price="price"
                    v-bind:is-blocked="isRecalculationRequired || price === null"
                />
            </div>
        </template>
        <template v-if="step === 'success'">
            <div class="modal-prepare-invest-offer__title">
                Инвестиционное предложение подготовлено!
            </div>
            <div class="modal-prepare-invest-offer__subtitle">
                Ожидайте окончания генерации необходимых документов
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-prepare-invest-offer',
    props: {
        /**
         * @type {EstateEditGetTransformed}
         */
        estate: {
            type: Object,
        },
        /**
         * @type {IssueDashboardTransformed}
         */
        issue: {
            type: Object,
        },
    },
    data: () => ({
        step: 'form',
        isRecalculationRequired: false,
        price: null,
    }),
    computed: {
        ...mapState('admin/investments', [
            'generateInvestmentOfferDocumentsResult',
            'generateInvestmentOfferDocumentsIsLoading',
            'generateInvestmentOfferDocumentsError',

            'calculateSharesResult',
            'calculateSharesIsLoading',
            'calculateSharesError',
        ]),
        isLoading() {
            return this.generateInvestmentOfferDocumentsIsLoading ||
                this.calculateSharesIsLoading;
        },
    },
    methods: {
        successHandler() {
            this.step = 'success';
        },
        recalculationHandler(newVal) {
            this.isRecalculationRequired = newVal;
        },
        setPrice(newVal) {
            this.price = newVal;
        },
    },
    watch: {
        generateInvestmentOfferDocumentsResult(newVal) {
            if (newVal) {
                this.successHandler();
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estate.id });
            }
        },
    },
    beforeDestroy() {
        this.$store.state.admin.investments.calculateSharesResult = null;
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-prepare-invest-offer {
    .modal();

    position: relative;

    max-width: 960px;
    width: 100%;
    padding: 40px 24px 32px;
    border-radius: @border-radius-lg;
    overflow: hidden;

    background-color: @color-gray-lightest;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 75%);
        &-container {
            width: 50px;
            padding-top: 8px;
        }
    }
    &__title {
        .typography-large();

        margin: 0 auto 8px;

        text-align: center;
    }
    &__subtitle {
        .typography-small();

        margin: 0 auto 24px;

        text-align: center;
    }
    &__price {
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
