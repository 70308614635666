// import moment from 'moment';
import { date as dateTransform } from '~/network/mainClient/common/transforms/responses';
import { item as baseEstateItemTransform } from '~/network/mainClient/estates/transforms/responses';
import config from '~/config';

export const investmentType = (data) => {
    return {
        id: data.id || -1,
        code: data.name || 'unknown',
        title: config.common.investmentTypes.find(x => x.code === data.name)?.title || 'Неизвестный тип',
    };
};

/**
 * Преобразует объект CalculateSharesResponse в объект для отображения результатов расчета.
 * @param {CalculateSharesResponse} data
 * @returns {Object}
 * @property {number} totalAmount - Общая сумма расчетов.
 * @property {number} totalQuantity - Общая количество предметов или единиц в распределении.
 * @property {number} totalRemainder - Оставшийся остаток после распределения.
 * @property {Object<string, Array<string>>} shares
 */
export const shareCalculation = (data) => {
    return {
        totalAmount: data.total_amount,
        totalQuantity: data.total_quantity,
        totalRemainder: data.total_remainder,
        shares: data.shares,
    };
};

// admin estate info field
export const info = (data) => {
    if (data === null) {
        return {
            id: -1,
            maxLoanTerm: null,
            shareCapital: '',
        };
    }
    return {
        id: data.id,
        maxLoanTerm: dateTransform(data.max_loan_term),
        shareCapital: parseFloat(data.share_capital),
    };
};

export const adminEstateItem = (data) => {
    const result = {
        // commented fields below are filled from ...baseEstateItemTransform()-transform
        ...baseEstateItemTransform(data),
        // id: data.id || -1,
        // balance: data.balance || 0,
        balanceSigned: data.signed_balance || 0,
        // status: data.status || 'new',
        // url: data.url || '#',

        // general
        // title: data.name || 'Название объекта',
        // type: type(data.type),
        // address: data.address || '',
        description: data.description || '',
        totalValue: data.total_cost || 0,
        currentDividendProfitability: data.current_dividend_profitability || 0,
        target: data.target || 0,
        targetDividendProfitability: data.target_profitability || 0,
        // yearPercent: data.year_percent ? parseFloat(data.year_percent) : 0,
        capRate: data.cap_rate || 0,
        totalSquare: data.gba || 0,
        rentableSquare: data.gla || 0,
        occupancy: data.occupancy || 0,
        minimalInvestmentAmount: data.minimum_investment_amount || 0,
        annualIndexing: data.annual_indexing || 0,
        info: info(data.info),
        // image
        // image: data.image || null,
        // document
        documentTitle: data.document_title || '',
        document: data.document || null,
        // activity
        isActive: !!data.is_active,

        // legal
        // general
        orgTitle: data.juridical_info.organization_name || '',
        orgAddress: data.juridical_info.organization_address || '',
        orgJurAddress: data.juridical_info.legal_address || '',
        orgMailAddress: data.juridical_info.mailing_address || '',
        ogrn: data.juridical_info.ogrn || '',
        okved: data.juridical_info.okved || '',
        inn: data.juridical_info.inn || '',
        registrationDate: dateTransform(data.juridical_info.date_registration) || '',
        // banking data
        bankTitle: data.juridical_info.bank_name || '',
        kpp: data.juridical_info.kpp || '',
        bik: data.juridical_info.bik || '',
        bankAccount: data.juridical_info.bank_account || '',
        cadastralAccount: data.juridical_info.cadastral_account || '',
    };
    return result;
};

export const adminEstateSharesListItem = (data) => {
    return {
        date: dateTransform(data.modified),
        name: [
            data.user.last_name,
            data.user.first_name,
            data.user.second_name,
        ].filter(x => !!x).join(' '),
        quantity: data.quantity,
        account: '???',
        file: '#???',
    };
};

export const adminEstateSharesList = (data) => {
    const result = {
        items: data.results.map(adminEstateSharesListItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
    };
    return result;
};
