var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-estates-list"},[_vm._m(0),_c('section',{staticClass:"page-estates-list__list"},[_c('div',{staticClass:"page-estates-list__list-content"},[(_vm.isAnySubscriptionAvailable)?_c('div',{staticClass:"page-estates-list__list-content-item"},[_c('page-estates-list-form')],1):_vm._e(),_vm._l((_vm.accumulatedItems),function(item,index){return _c('div',{key:index,staticClass:"page-estates-list__list-content-item"},[(item.isSubscription)?_c('page-estates-list-subscription'):_c('common-card-estate',{attrs:{"item":item,"resizes":{
                        1920: 24,
                        1599: 35,
                        1279: 30,
                        1023: 46,
                        767: 100,
                    }}})],1)})],2),(_vm.isLoading)?_c('div',{staticClass:"page-estates-list__list-loader"},[_c('ui-loader',{attrs:{"fixed":50,"centered":true}})],1):_vm._e(),(!_vm.isLoading && _vm.pagingTotal > _vm.pagingCurrent)?_c('div',{staticClass:"page-estates-list__list-buttons"},[_c('div',{staticClass:"page-estates-list__list-buttons-item",on:{"click":_vm.getMore}},[_c('ui-button',[_vm._v(" Показать еще ")])],1)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-estates-list__headline"},[_c('h1',{staticClass:"page-estates-list__headline-title"},[_vm._v(" Объекты SimpleEstate ")])])
}]

export { render, staticRenderFns }