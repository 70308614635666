import { render, staticRenderFns } from "./legal-block.vue?vue&type=template&id=383dbf3a&scoped=true"
import script from "./legal-block.vue?vue&type=script&lang=js"
export * from "./legal-block.vue?vue&type=script&lang=js"
import style0 from "./legal-block.vue?vue&type=style&index=0&id=383dbf3a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "383dbf3a",
  null
  
)

export default component.exports