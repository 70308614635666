import { render, staticRenderFns } from "./prefooter.vue?vue&type=template&id=b68f3072&scoped=true"
import script from "./prefooter.vue?vue&type=script&lang=js"
export * from "./prefooter.vue?vue&type=script&lang=js"
import style0 from "./prefooter.vue?vue&type=style&index=0&id=b68f3072&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b68f3072",
  null
  
)

export default component.exports