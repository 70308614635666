export const UserStatus = {
    NEW: 'new',
    UNVERIFIED: 'unverified',
    VERIFIED: 'verified',
    DECLINED: 'declined',
    FROZEN: 'frozen',
    REVALIDATION_REQUIRED: 'revalidation_required',
};

export const EstateStatus = {
    NEW: 'new',
    COMPLETED: 'completed',
    SOLD: 'sold',
    _COLLECTED: 'collected',
    _COLLECTING: 'collecting',
    _ROUND_COMPLETED: 'round-completed',
};

export const IssueStatus = {
    NEW: 'new',
    COLLECTED: 'collected',
    DOCUMENTS_IN_PROGRESS: 'documents_in_progress',
    DOCUMENTS_READY: 'documents_ready',
    WAIT_SIGN: 'wait_sign',
    SIGNED: 'signed',
    LOAN_PROCESSING: 'loan_processing',
    LOAN_ISSUED: 'loan_issued',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
    _NOT_STARTED: 'not-started',
    _COLLECTING: 'collecting',
    _SINGING: 'signing',
};

export const RequestStatus = {
    NEW: 'new',
    WAITING: 'waiting',
    WAIT_SIGN: 'wait_sign',
    SIGNED: 'signed',
    CANCELED: 'canceled',
    COMPLETED: 'completed',
    MERGED: 'merged',
    REFUNDED: 'refunded',
};

export const InvestmentTypeName = {
    DIRECT: 'direct',
    LOAN: 'loan',
};
