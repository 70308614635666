<template>
    <section class="page-admin-estates-item-requests-footer" v-if="getEstateRoundsResult">
        <div class="page-admin-estates-item-requests-footer__indicator">
            <div class="page-admin-estates-item-requests-footer__indicator-bar"
                v-bind:class="{
                    _accented: isFilled,
                    _positive: isSignsFilled,
                }"
                v-bind:style="{ transform: `translateX(${progress - 100}%)` }"
            />
        </div>
        <div class="page-admin-estates-item-requests-footer__content">
            <div class="page-admin-estates-item-requests-footer__content-balance">
                <div class="page-admin-estates-item-requests-footer__content-balance-item">
                    <div class="page-admin-estates-item-requests-footer__content-balance-item-title"
                        v-text="'Всего собрано:'"
                    />
                    <div class="page-admin-estates-item-requests-footer__content-balance-item-value">
                        <span class="page-admin-estates-item-requests-footer__content-balance-item-value-number"
                            v-text="getFormattedNumber(balanceSigned)"
                        /> из <span class="page-admin-estates-item-requests-footer__content-balance-item-value-number"
                            v-text="getFormattedNumber(target) + ' ₽'"
                        />
                    </div>
                </div>
                <div class="page-admin-estates-item-requests-footer__content-balance-item"
                    v-if="activeCampaign"
                    >
                    <div class="page-admin-estates-item-requests-footer__content-balance-item-title"
                        v-text="`В раунде ${activeCampaign.index + 1} подписано:`"
                    />
                    <div class="page-admin-estates-item-requests-footer__content-balance-item-value">
                        <span class="page-admin-estates-item-requests-footer__content-balance-item-value-number"
                            v-text="getFormattedNumber(activeRoundSignedBalance)"
                        /> из <span class="page-admin-estates-item-requests-footer__content-balance-item-value-number"
                            v-text="getFormattedNumber(activeRoundBalance) + ' ₽'"
                        />
                    </div>
                </div>
            </div>
            <div class="page-admin-estates-item-requests-footer__content-text _error"
                v-if="isAnyInvalid"
                v-text="'Проверьте данные в разделах Информация и Юридические данные'"
            />
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'page-admin-estates-item-requests-footer',
    props: {
        estateId: {
            type: Number,
        },
        isAnyInvalid: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        cancelLoanRequired: false,
    }),
    computed: {
        ...mapState('admin/estates', [
            'getEstateRequestsIsLoading',
            'getEstateRequestsResult',
            'getEstateRequestsError',

            'updateEstateInfoIsInvalid',
            'updateEstateInfoIsLoading',

            'getEstateInfoIsLoading',
            'getEstateInfoError',
            'getEstateInfoResult',

            'getEstateRequestsIsLoading',
            'getEstateRequestsResult',
            'getEstateRequestsError',

            'getEstateRoundsIsLoading',
            'getEstateRoundsError',
            'getEstateRoundsResult',
        ]),
        ...mapState('admin/investments', [
            'cancelExpiredInvestmentRequestsIsLoading',
            'cancelExpiredInvestmentRequestsError',
            'cancelExpiredInvestmentRequestsResult',

            'transferMoneyIsLoading',
            'transferMoneyError',
            'transferMoneyResult',

            'convertEstateToSharesIsLoading',
            'convertEstateToSharesError',
            'convertEstateToSharesResult',
        ]),
        ...mapState('admin/loans', [
            'getLoanIsLoading',
            'getLoanError',
            'getLoanResult',

            'cancelLoanIsLoading',
            'cancelLoanError',
            'cancelLoanResult',
        ]),
        balance() {
            return this.getEstateInfoResult.balance;
        },
        balanceSigned() {
            return this.getEstateInfoResult.balanceSigned;
        },
        target() {
            return this.getEstateInfoResult.target;
        },
        progress() {
            // if (this.activeCampaign) {
            //     if (this.activeCampaign.displayMode === 'collecting') {
            //         return Math.min(this.activeCampaign.balance / this.activeCampaign.target * 100, 100);
            //     } else {
            //         return Math.min(this.activeCampaign.balanceSigned / this.activeCampaign.balance * 100, 100);
            //     }
            // }
            return Math.min(this.balance / this.target * 100, 100);
        },
        isFilled() {
            return true || this.progress >= 100;
        },
        isSignsFilled() {
            return this.balanceSigned >= this.balance && this.balanceSigned > 0;
        },
        status() {
            return this.getEstateInfoResult?.status;
        },
        isMobile() {
            return window.innerWidth < 768;
        },
        activeRound() {
            return this.getEstateRoundsResult?.find(r => r.isActive);
        },
        activeCampaign() {
            if (!this.getEstateRoundsResult) {
                return null;
            }
            return this.activeRound || null;
        },
        activeRoundSignedBalance() {
            if (this.activeRound) {
                return this.activeRound.balanceSigned;
            }
            return null;
        },
        activeRoundBalance() {
            if (this.activeRound) {
                return this.activeRound.balance;
            }
            return null;
        },
    },
    methods: {
        getFormattedNumber(number) {
            return utils.formats.formatNumberWithSpaces(number);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-requests-footer {
    background-color: @color-gray-lighter;
    &__indicator {
        height: 2px;

        background-color: fade(@color-gray-darkest, 10%);
        &-bar {
            height: 100%;

            background-color: @color-gray-darkest;
            &._accented {
                background-color: @color-primary;
            }
            &._positive {
                background-color: @color-positive;
            }
        }
    }
    &__content {
        .container();
        .container-paddings();

        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 15px;
        padding-bottom: 15px;
        &-balance {
            flex: 0 0 auto;
            &-item {
                display: flex;
                align-items: baseline;
                &-title {
                    .typography-heading();

                    margin-right: 5px;
                }
                &-value {
                    .typography-caption();
                    &-accent {
                        .typography-body();
                    }
                }
            }
        }
        &-loan {
            flex: 0 0 auto;
            display: flex;
            align-items: baseline;
            &-title {
                .typography-heading();

                margin-right: 5px;
            }
            &-value {
                .typography-body();
            }
        }
        &-text {
            .typography-body();

            flex: 1 1 auto;
            margin: auto 24px;

            text-align: right;
            &._error {
                color: @color-accent-negative;
            }
        }
        &-buttons {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            margin: -12px;
            padding-left: 24px;
            &-item {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 12px;
                &-text {
                    .typography-body();

                    flex: 1 1 auto;
                    margin: auto 24px;

                    text-align: right;
                    &._error {
                        color: @color-accent-negative;
                    }
                }
            }
        }
    }
    @media @media-sm-down {
        &__content {
            display: block;
            padding-top: 24px;
            padding-bottom: 24px;

            text-align: center;
            &-balance {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 16px;
                &-item {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
            &-loan {
                display: block;
                margin-bottom: 16px;
                &-title {
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }
            &-text {
                margin-bottom: 16px;

                text-align: center;
            }
            &-buttons {
                padding-left: 0;
                margin: -8px;
                flex-direction: column;
                justify-content: center;
                &-item {
                    padding: 8px;
                }
            }
        }
    }
}
</style>
