import config from '~/config';
import {
    inn as otherInn,
    ogrn as commonOgrn,
    okved as commonOkved,
} from '~/forms/fields/other';

// general info block
// image
export const image = {
    name: 'image',
    label: 'Изображение',
    value: null,
    type: 'image',
    accept: '.jpg, .jpeg, .png',
    validFileTypes: [ 'image/jpeg', 'image/pjpeg', 'image/png' ],
    initialValue: null,
    errors: {
        required: 'Загрузите изображение',
    },
};
// top
export const title = {
    name: 'title',
    label: 'Название объекта',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите название объекта',
    },
};

export const type = {
    name: 'type',
    label: 'Тип недвижимости',
    value: '',
    initialValue: '',
    type: 'select',
    errors: {
        required: 'Выберите тип недвижимости',
    },
};

export const address = {
    name: 'address',
    label: 'Адрес',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите адрес объекта',
    },
};

export const description = {
    name: 'description',
    label: 'Общая информация',
    value: '',
    initialValue: '',
    type: 'textarea',
    errors: {
        required: 'Введите описание объекта',
    },
};

// bottom
export const totalValue = {
    name: 'totalValue',
    label: 'Общая стоимость',
    value: '',
    initialValue: '',
    type: 'currency',
    units: '₽',
    errors: {
        required: 'Введите стоимость объекта',
        isValid: 'Введите только цифры',
    },
};

export const target = {
    name: 'target',
    label: 'Цель',
    value: '',
    initialValue: '',
    type: 'currency',
    units: '₽',
    errors: {
        required: 'Введите целевую сумму',
        isValid: 'Введите только цифры',
    },
};

export const sharePrice = {
    name: 'sharePrice',
    label: 'Стоимость акции',
    value: '',
    initialValue: '',
    type: 'currency',
    units: '₽',
    errors: {
        required: 'Введите стоимость акции',
        isValid: 'Введите только цифры',
    },
};

export const currentDividendProfitability = {
    name: 'currentDividendProfitability',
    label: 'Текущая дивидендная доходность',
    value: '',
    initialValue: '',
    type: 'float',
    units: '%',
    errors: {
        required: 'Введите значение дивидендной доходности',
        isValid: 'Введите число',
    },
};

export const targetDividendProfitability = {
    name: 'targetDividendProfitability',
    label: 'Прогнозируемая доходность',
    value: '',
    initialValue: '',
    type: 'float',
    units: '%',
    errors: {
        required: 'Введите значение целевой доходности',
        isValid: 'Введите число',
    },
};

export const yearPercent = {
    name: 'yearPercent',
    label: 'Процент годовых по займу',
    value: '',
    initialValue: '',
    type: 'float',
    units: '%',
    errors: {
        required: 'Введите значение процента годовых',
        isValid: 'Введите число',
    },
};

export const capRate = {
    name: 'capRate',
    label: 'Cap Rate',
    value: '',
    initialValue: '',
    type: 'float',
    units: '%',
    errors: {
        required: 'Введите значение Cap Rate',
        isValid: 'Введите число',
    },
};

export const totalSquare = {
    name: 'totalSquare',
    label: 'Общая площадь',
    value: '',
    initialValue: '',
    type: 'numeric',
    firstZeroInavailable: true,
    units: 'м²',
    errors: {
        required: 'Введите общую площадь объекта',
        isValid: 'Введите только цифры',
    },
};

export const rentableSquare = {
    name: 'rentableSquare',
    label: 'Арендуемая площадь',
    value: '',
    initialValue: '',
    type: 'numeric',
    firstZeroInavailable: true,
    units: 'м²',
    errors: {
        required: 'Введите ареднуемую площадь объекта',
        isValid: 'Введите только цифры',
    },
};

export const occupancy = {
    name: 'occupancy',
    label: 'Заполняемость',
    value: '',
    initialValue: '',
    type: 'numeric',
    firstZeroInavailable: true,
    units: '%',
    errors: {
        required: 'Введите заполняемость объекта',
        isValid: 'Введите только цифры',
    },
};

export const minimalInvestmentAmount = {
    name: 'minimalInvestmentAmount',
    label: 'Минимальный размер инвестиции',
    value: '',
    initialValue: '',
    type: 'currency',
    units: '₽',
    errors: {
        required: 'Введите минимальный размер инвестиции',
        isValid: 'Введите только цифры',
    },
};

export const tradeMinInvestmentAmount = {
    name: 'tradeMinInvestmentAmount',
    label: 'Минимальная сумма сделки на вторичке',
    value: '',
    initialValue: '',
    type: 'currency',
    units: '₽',
    errors: {
        required: 'Введите минимальную сумму сделки на вторичке',
        isValid: 'Введите только цифры',
    },
};

export const annualIndexing = {
    name: 'annualIndexing',
    label: 'Ежегодная индексация',
    value: '',
    initialValue: '',
    type: 'float',
    units: '%',
    errors: {
        required: 'Введите значение ежегодной индексации',
        isValid: 'Введите число',
    },
};

export const shareCapital = {
    name: 'shareCapital',
    label: 'Уставной капитал',
    value: '',
    initialValue: '',
    type: 'currency',
    units: '₽',
    errors: {
        required: 'Введите объем уставного капитала',
        isValid: 'Введите только цифры',
    },
};

export const maxLoanTerm = {
    name: 'maxLoanTerm',
    label: 'Срок займа',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите максимальный срок займа',
        isValid: 'Введите корректную дату',
    },
};

// document
export const documentTitle = {
    name: 'documentTitle',
    label: 'Название документа',
    value: 'Презентация по объекту',
    initialValue: 'Презентация по объекту',
    errors: {
        required: 'Введите название документа',
    },
};

export const document = {
    name: 'document',
    text: 'Загрузить файл',
    type: 'file',
    value: null,
    initialValue: null,
    // formats: [],
    errors: {
        required: 'Загрузите файл',
    },
};

// activity
export const isActive = {
    name: 'isActive',
    label: 'Активен',
    type: 'checkbox',
    value: false,
};

// legal info
export const orgTitle = {
    name: 'orgTitle',
    label: 'Название организации',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите название организации',
    },
};

export const orgAddress = {
    name: 'orgAddress',
    label: 'Адрес организации',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите адрес организации',
    },
};

export const orgJurAddress = {
    name: 'orgJurAddress',
    label: 'Юридический адрес',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите юридический адрес',
    },
};

export const orgMailAddress = {
    name: 'orgMailAddress',
    label: 'Почтовый адрес',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите почтовый адрес',
    },
};

export const organizationNameAcronym = {
    name: 'organizationNameAcronym',
    label: 'Акроним названия организации (АО)',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите акроним названия организации',
        isValid: 'Введите корректный акроним организации',
    },
};

export const ogrn = {
    ...commonOgrn,
    maxLength: null,
    errors: {
        required: `Введите ОГРН`,
        isValid: `Введите корректный ОГРН`,
    },
};

export const okved = commonOkved;

export const inn = {
    ...otherInn,
    minLength: 10,
    maxLength: 10,
    errors: {
        required: 'Введите ИНН',
        isValid: `Введите корректный ИНН (10 цифр)`,
    },
};

export const registrationDate = {
    name: 'registrationDate',
    label: 'Дата регистрации АО',
    type: 'date',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите дату регистрации АО',
        isValid: `Введите корректную дату`,
    },
};

// banking
export const bankTitle = {
    name: 'bankTitle',
    label: 'Название банка',
    value: '',
    initialValue: '',
    errors: {
        required: 'Введите название банка',
    },
};

export const kpp = {
    name: 'kpp',
    label: 'КПП',
    value: '',
    initialValue: '',
    type: 'numeric',
    errors: {
        required: 'Введите КПП',
        isValid: `Введите корректный цифр КПП`,
    },
};

export const bik = {
    name: 'bik',
    label: 'БИК',
    value: '',
    initialValue: '',
    type: 'numeric',
    maxLength: config.common.bikLength,
    errors: {
        required: 'Введите БИК',
        isValid: `Введите ${config.common.bikLength} цифр БИК`,
    },
};

export const bankAccount = {
    name: 'bankAccount',
    label: 'Расчетный счет',
    value: '',
    initialValue: '',
    type: 'numeric',
    errors: {
        required: 'Введите номер расчетного счета',
        isValid: 'Введите только цифры',
    },
};

export const cadastralAccount = {
    name: 'cadastralAccount',
    label: 'Корреспондентский счет',
    value: '',
    initialValue: '',
    type: 'numeric',
    errors: {
        required: 'Введите номер кадастрового счета',
        isValid: 'Введите только цифры',
    },
};

export const initialBalance = {
    name: 'initialBalance',
    label: 'Фейковый баланс',
    value: '',
    initialValue: '',
    type: 'numeric',
    errors: {
        isValid: 'Введите только цифры',
    },
};

// // passport
// export const passportNumber = {
//     name: 'passportNumber',
//     label: 'Серия и номер паспорта',
//     value: '',
//     initialValue: '',
//     type: 'passport',
//     errors: {
//         required: 'Введите серию и номер паспорта',
//         isValid: 'Используйте только цифры и пробел',
//         tooLong: 'Введите 4 цифры серии и 6 цифр номера паспорта',
//     },
// };

// export const passportDate = {
//     name: 'passportDate',
//     label: 'Дата выдачи паспорта',
//     value: '',
//     initialValue: '',
//     type: 'date',
//     errors: {
//         required: 'Введите дату выдачи паспорта',
//         isValid: 'Введите корректную дату в формате ДД.ММ.ГГГГ',
//     },
// };

// export const passportIssuer = {
//     name: 'passportIssuer',
//     label: 'Кем выдан паспорт',
//     value: '',
//     initialValue: '',
//     errors: {
//         required: 'Введите название организации, выдавшей паспорт',
//     },
// };

// export const passportIssuerCode = {
//     name: 'passportIssuerCode',
//     label: 'Код подразделения',
//     value: '',
//     initialValue: '',
//     type: 'passport-code',
//     errors: {
//         required: 'Введите код подразделения организации, выдавшей паспорт',
//         isValid: 'Введите код в формате 000-000',
//     },
// };

// export const passportAddress = {
//     name: 'passportAddress',
//     label: 'Адрес регистрации',
//     value: '',
//     initialValue: '',
//     errors: {
//         required: 'Введите адрес регистрации из паспорта',
//     },
// };

// export const passportBirthDate = {
//     name: 'passportBirthDate',
//     label: 'Дата рождения',
//     value: '',
//     initialValue: '',
//     type: 'date',
//     errors: {
//         required: 'Введите дату рождения из паспорта',
//         isValid: 'Введите корректную дату в формате ДД.ММ.ГГГГ',
//     },
// };
