const state = {
    list: [],
};

const getters = {
    list(state) {
        return state.list;
    },
};

const actions = {
};

const mutations = {
    push(state, modal) {
        let itemToAdd = modal;
        if (typeof modal === 'string') {
            itemToAdd = {
                name: modal,
                props: {},
            };
        }
        if (!itemToAdd.props) {
            itemToAdd.props = {};
        }
        state.list = state.list.concat([itemToAdd]);
    },
    pop(state) {
        const targetIndex = state.list.length - 1;
        const onClose = state.list[targetIndex]?.props?.onClose;
        if (typeof onClose === 'function') {
            onClose();
        }
        state.list = state.list.slice(0, targetIndex);
    },
    popByIndex(state, index) {
        const onClose = state.list[index].props.onClose;
        if (onClose !== undefined) {
            onClose();
        }
        state.list = state.list.filter((x, xi) => xi !== index);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
