// import moment from 'moment';
import { date as dateTransform } from '~/network/mainClient/common/transforms/responses';
import { userProfile as userProfileTransform } from '~/network/mainClient/users/transforms/responses';
import config from '~/config';
import utils from '~/utils';
import '~/types/transforms';
import '~/types/api';
import { EstateStatus, IssueStatus, RequestStatus } from '~/constants';

export const investmentType = (data) => {
    return {
        id: data.id || -1,
        code: data.name || 'unknown',
        title: config.common.investmentTypes.find(x => x.code === data.name)?.title || 'Неизвестный тип',
    };
};

/**
 * Трансформер для Объекта недвижимости
 * @param {EstateEditGet | EstateJson} data - Объект с данными объекта Недвижимости.
 * @returns {EstateTransformed}
 */
export const item = (data) => {
    const result = {
        id: data.id,
        title: data.name || 'Название объекта',
        description: data.description || 'У этого объекта еще нет описания',
        type: type(data.type),
        document: data.document
            ? { title: data.document_title || 'Презентация по объекту', url: data.document }
            : null,
        image: data.image || 'https://placeimg.com/640/640/arch',
        video: data.youtube_video || null,
        address: data.address || 'Адрес не указан',
        targetProfitability: data.finance ? parseFloat(data.finance.target_profitability || 0) : 0,
        currentProfitability: data.finance ? parseFloat(data.finance.current_profitability || 0) : 0,
        targetDividendProfitability: data.finance ? parseFloat(data.finance.target_dividend_profitability || 0) : 0,
        currentDividendProfitability: data.finance ? parseFloat(data.finance.current_dividend_profitability || 0) : 0,
        sharePrice: parseFloat(data.share_price) || 0.01,
        sharePriceFormatted: utils.formats.formatNumberWithSpaces(999, true), // TODO not use
        yearPercent: data.year_percent ? parseFloat(data.year_percent) : 0,
        minimumInvestment: data.finance ? parseFloat(data.finance.minimum_investment_amount) : 0,
        totalCost: data.finance ? parseFloat(data.finance.total_cost) : 0,
        url: data.url || (data.id > -1 ? `${config.urls.estates.list}${data.id}` : '#'),
        balance: parseFloat(data.balance) || 0,
        canInvest: data.can_invest,
        target: data.finance ? data.finance.target : 0,
        capRate: data.finance ? parseFloat(data.finance.cap_rate) : 0,
        gba: data.gba || 0,
        gla: data.gla || 0,
        occupancy: data.occupancy || 0,
        currency: 'руб.',
        investments: 0,
        dividends: 0,
        income: 0,
        irr: 0,
        mm: 0,
        share: 0,
        status: data.status || EstateStatus.NEW,
        state: EstateStatus._COLLECTING,
        collectingProgress: 0,
        availableInvestmentTypes: data.investment_types.map(investmentType),
        isLoanInvestmentTypeAvailable: data.investment_types.some(x => x.name === 'loan'),
        quarterlyReports: data.quarterly_reports || [],
        targetThresholdMultiplier: parseInt(data.overage_limit || 0, 10) / 100 + 1,
        currentInvestmentRequestId: data.current_investment_request_id,
    };
    const isFullByStatus = [
        'collected', // TODO старые статусы. Новые: new, completed, sold
        'wait_sign',
        'signed',
        'loan_issued',
        'loan_converted',
        EstateStatus.COMPLETED,
    ].indexOf(result.status) > -1;

    if (result.status === EstateStatus.SOLD) {
        result.state = EstateStatus.SOLD;
    } else if (result.status === EstateStatus.COMPLETED) {
        result.state = EstateStatus.COMPLETED;
    } else if (!result.canInvest || isFullByStatus || (result.balance >= result.target * result.targetThresholdMultiplier)) {
        result.state = EstateStatus._COLLECTED;
    }
    const getHumanizedSum = (sum, onlyNumber = false, referenceSum = null, withCurrency = true) => {
        const sumToUse = referenceSum || sum;
        let sumResult = '';
        if (sumToUse < 1000) {
            return sum !== 0 ? sum + ' ₽' : sum;
        } else if (sumToUse < 1000000) {
            sumResult = Math.floor(sum / 1000).toFixed(0) + (onlyNumber ? '' : ' тыс');
        } else if (sumToUse < 1000000000) {
            sumResult = Math.floor(sum / 1000000).toFixed(0) + (onlyNumber ? '' : ' млн');
        } else {
            sumResult = Math.floor(sum / 1000000000).toFixed(0) + (onlyNumber ? '' : ' млрд');
        }
        return withCurrency ? sumResult + ' ₽' : sumResult;
    };
    const getBalanceStateText = campaign => {
        if (!campaign.isActive && !campaign.isCompleted) {
            return 'Не начато';
        }
        if (result.status !== EstateStatus._COLLECTING || campaign.isCompleted || campaign.balance >= campaign.target) {
            return getHumanizedSum(campaign.target);
        }
        const isBothUnitsNeeded = Math.ceil(campaign.target.toString().length / 3) !== Math.ceil(campaign.balance.toString().length / 3);
        return getHumanizedSum(campaign.balance, !isBothUnitsNeeded, isBothUnitsNeeded ? null : campaign.target, false) + ' / ' + getHumanizedSum(campaign.target);
    };
    let isActiveCampaignFound = false;
    let cumulativeTarget = 0;
    result.investmentCampaigns = data.investment_rounds ? data.investment_rounds.map((round, i) => {
        if (round.is_active) {
            isActiveCampaignFound = true;
        }
        const enrichedCampaign = {
            index: i,
            target: round.target,
            isActive: round.is_active,
            isCompleted: !isActiveCampaignFound,
            // balance: Math.max(result.balance - cumulativeTarget, 0),
            balance: parseFloat(round.balance) || 0,
        };
        enrichedCampaign.balanceStateText = getBalanceStateText(enrichedCampaign);
        cumulativeTarget += round.target;
        return enrichedCampaign;
    }) : null;

    if (
        result.status === EstateStatus.COMPLETED &&
        result.investmentCampaigns.findIndex(x => x.isActive) > -1 &&
        (result.investmentCampaigns.findIndex(x => x.isActive) + 1) < result.investmentCampaigns.length
    ) {
        result.state = EstateStatus._ROUND_COMPLETED;
    }

    result.collectingProgress = result.target === 0 ? 1 : Math.max(Math.min(result.balance / result.target, 1), 0);

    return result;
};

export const list = (data) => {
    const result = {
        items: data.results.map(item),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};

export const type = (data) => {
    if (!data) {
        return {
            id: -1,
            title: 'Особый',
            isActive: false,
            icon: 'estate-type-street-retail',
        };
    }
    const typeOfData = typeof data;
    if (typeOfData === 'string' || typeOfData === 'number') {
        return {
            id: data,
            title: 'Особый',
            isActive: false,
            icon: 'estate-type-street-retail',
        };
    }
    return {
        id: data.id || -1,
        title: data.name || 'Особый',
        isActive: data.is_active || false,
        icon: data.icon ? 'estate-type-' + data.icon : 'estate-type-street-retail',
    };
};

export const typesList = (data) => {
    const result = {
        items: data.results.map(type),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};

export const chartData = (data) => {
    const sortedYears = Object.keys(data).sort((a, b) => a - b);
    const result = sortedYears.reduce((acc, year, yearIndex) => {
        const quarterOffset = yearIndex === 0 ? 4 - data[year].length : 0;
        data[year].forEach((quarterValue, quarterIndex) => {
            acc.push({
                category: `${quarterIndex + 1 + quarterOffset} кв.\n${year}`,
                value: quarterValue,
            });
        });
        return acc;
    }, []);
    return result;
};

// admin estate info field
export const info = (data) => {
    if (data === null) {
        return {
            id: -1,
            maxLoanTerm: null,
            shareCapital: '',
        };
    }
    return {
        id: data.id,
        maxLoanTerm: dateTransform(data.max_loan_term),
        shareCapital: parseFloat(data.share_capital),
    };
};

/**
 * Трансформер для DSUR Документа
 * @param {EstateDocument} data - Объект с данными объекта Недвижимости.
 * @returns {EstateDocumentTransformed}
 */
export const adminEstateItemDocument = (data) => {
    return {
        id: data.id,
        title: data.title,
        url: data.file,
        slug: data.slug,
    };
};

/**
 * Трансформер для Объекта недвижимости
 * @param {EstateEditGet} data - Объект с данными объекта Недвижимости.
 * @returns {EstateEditGetTransformed}
 */
export const adminEstateItem = (data) => {
    const result = {
        // commented fields below are filled from ...item()-transform
        ...item(data),
        // id: data.id || -1,
        // balance: data.balance || 0,
        balanceSigned: data.signed_balance || 0,
        initialBalance: parseFloat(data.initial_balance) || 0,
        // status: data.status || 'new',
        // url: data.url || '#',

        // general
        // title: data.name || 'Название объекта',
        // type: type(data.type),
        // address: data.address || '',
        description: data.description || '',
        totalValue: data.total_cost || 0,
        currentDividendProfitability: data.current_dividend_profitability || 0,
        target: data.target || 0,
        targetDividendProfitability: data.target_profitability || 0,
        // yearPercent: data.year_percent ? parseFloat(data.year_percent) : 0,
        capRate: data.cap_rate || 0,
        totalSquare: data.gba || 0,
        rentableSquare: data.gla || 0,
        occupancy: data.occupancy || 0,
        minimalInvestmentAmount: data.minimum_investment_amount || 0, // TODO
        tradeMinInvestmentAmount: data.trade_min_investment_amount,
        annualIndexing: data.annual_indexing || 0,
        info: info(data.info),
        // image
        // image: data.image || null,
        // document
        documentTitle: data.document_title || '',
        document: data.document || null,
        // activity
        isActive: !!data.is_active,

        // legal
        // general
        orgTitle: data.juridical_info.organization_name || '',
        orgAddress: data.juridical_info.organization_address || '',
        orgJurAddress: data.juridical_info.legal_address || '',
        orgMailAddress: data.juridical_info.mailing_address || '',
        organizationNameAcronym: data.juridical_info.organization_name_acronym || '',
        ogrn: data.juridical_info.ogrn || '',
        okved: data.juridical_info.okved || '',
        inn: data.juridical_info.inn || '',
        registrationDate: dateTransform(data.juridical_info.date_registration) || '',
        // banking data
        bankTitle: data.juridical_info.bank_name || '',
        kpp: data.juridical_info.kpp || '',
        bik: data.juridical_info.bik || '',
        bankAccount: data.juridical_info.bank_account || '',
        cadastralAccount: data.juridical_info.cadastral_account || '',

        // files
        templates: data.templates?.map(x => adminEstateItemDocument(x)) || [],
        dsur: data.dsur_document ? { ...adminEstateItemDocument(data.dsur_document), title: 'ДСУР' } : null,
    };
    return result;
};

/**
 * Трансформер для Списка Раундов
 * @param {GetDashboardEstateRoundsResponseData} - Объект с данными для отображения списка раундов.
 * @returns {InvestmentRoundDashboardTransformed[]}
 */
export const adminEstateRoundsList = ({ results }) => {
    let isActiveCampaignFound = false;
    return results.map((round, roundIndex) => {
        if (round.is_active) {
            isActiveCampaignFound = true;
        }
        return {
            id: round.id,
            index: roundIndex,
            isActive: round.is_active,
            isCompleted: !isActiveCampaignFound,
            target: round.target,
            balance: round.balance || 0,
            balanceSigned: round.signed_balance || 0,
            balanceToShow: round.is_active ? round.balance : (round.signed_balance || 0),
            investorsCount: round.investors_count,
            sharePrice: parseFloat(round.share_price),
            minimumInvestmentAmount: round.minimum_investment_amount,
            issues: round.issues?.sort((a, b) => a.id - b.id).map((issue, issueIndex) => {
                return {
                    id: issue.id,
                    index: issueIndex,
                    status: issue.status || IssueStatus._NOT_STARTED,
                    displayMode: (issue.status === IssueStatus.NEW || issue.status === IssueStatus.COLLECTED) ? IssueStatus._COLLECTING : IssueStatus._SINGING,
                    sharePrice: parseFloat(round.share_price),
                    minimumInvestmentAmount: round.minimum_investment_amount,
                    // balanceToShow: (issue.status === IssueStatus.NEW || round.status === IssueStatus.COLLECTED) ? round.balance : (round.signed_balance || 0),
                    investmentAgreementCount: issue.investment_agreements_count,
                    investmentProposalCount: issue.investment_proposal_count,
                    transferOrderCount: issue.transfer_order_count,
                    investmentAcceptCount: issue.investment_accept_count,
                    requests: issue.investment_requests.map(x => adminEstateRequestsListItem(x, round)),
                    balance: issue.investment_requests.reduce(
                        (summ, request) => summ + (parseFloat(request.amount) || 0),
                        0,
                    ),
                    signedBalance: issue.investment_requests.reduce(
                        (summ, request) => (request.status === RequestStatus.SIGNED || request.status === RequestStatus.COMPLETED) ? summ + (parseFloat(request.amount) || 0) : 0,
                        0,
                    ),
                };
            }),
        };
    });
};

/**
 * Трансформер для Заявок
 * @param {DashboardInvestmentRequest} data
 * @param {InvestmentRoundDashboard} round
 * @returns {DashboardInvestmentRequestTransformed}
 */
export const adminEstateRequestsListItem = (data, round) => {
    const amount = data.amount ? parseFloat(data.amount) : 0;
    return {
        id: data.id || -1,
        created: dateTransform(data.created) || new Date(),
        amount,
        sharePrice: parseFloat(round.share_price),
        amountFormatted: utils.formats.formatNumberWithSpaces(amount, true),
        applicant: data.user ? { ...userProfileTransform(data.user), id: data.user.id } : null,
        account: data.user ? data.user.account_code : 'Неизвестно',
        status: data.status || RequestStatus.NEW,
        // is_active: data.is_active,
        reservedBalance: parseInt(data.reserved_balance?.amount, 10),
        round: data.investment_round_index || 0,
        transferOrder: data.transfer_order || null,
        investmentAgreement: data.investment_agreement || null,
        investmentProposal: data.investment_proposal || null,
        investmentAccept: data.investment_accept || null,
    };
};

export const adminEstateRequestsList = ({ results, count, page, ...data }) => {
    const result = {
        ...data,
        items: results.map(adminEstateRequestsListItem),
        paging: {
            count: count || 0,
            page: page || 1,
        },
    };
    return result;
};

export const adminEstateSharesListItem = (data) => {
    return {
        date: dateTransform(data.modified),
        name: [
            data.user.last_name,
            data.user.first_name,
            data.user.second_name,
        ].filter(x => !!x).join(' '),
        quantity: data.quantity,
        account: '???',
        file: '#???',
    };
};

export const adminEstateSharesList = (data) => {
    const result = {
        items: data.results.map(adminEstateSharesListItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
    };
    return result;
};
