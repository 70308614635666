<template>
    <div class="modal-edit-investment-round">
        <div class="modal-edit-investment-round__title">
            Редактирование раундов
        </div>
<!--        <div class="modal-edit-investment-round__description"></div>-->
        <div class="modal-edit-investment-round__heading">
            <span v-if="estate.type && estate.type.title">
                {{ estate.type.title }}
            </span>
            <span>{{ estate.title }}</span>
            <div v-if="estate.address">{{ estate.address }}</div>
        </div>
        <div style="display: flex; flex-direction: column; gap: 24px; overflow: hidden;">
            <div style="height: 100%; padding-right: 40px; overflow: auto; display: flex; flex-direction: column; gap: 28px;">
                <div class="modal-edit-investment-round__form" v-for="(round, index) in allRounds" :key="round.id">
                    <h1>{{round.index + 1}} Раунд</h1>
                    <ui-form
                        v-bind:model="fields[index]"
                        v-bind:validation="$v"
                        v-on:update="(data) => onFormUpdate(index, data, round)"
                    />
                    <div class="modal-edit-investment-round__form-error"
                         v-if="error"
                         v-text="error"
                    />
                </div>
            </div>
            <div style="flex-shrink: 0" class="modal-edit-investment-request__button">
                <ui-button
                    v-on:click="save"
                    theme="primary"
                >
                    Сохранить
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
// import { mapState } from 'vuex';
import forms from '~/forms';
import { mainClient } from '~/network';

export default {
    name: 'modal-edit-investment-round',
    props: {
        /**
         * @type {InvestmentRoundDashboardTransformed[]}
         */
        rounds: {
            type: Array,
        },
        estate: {
            type: Object,
        },
    },
    mounted() {
        this.buildRoundFields();
    },
    data: () => ({
        error: null,
        fields: [],
        changeCount: {},
    }),
    validations: {
        fields: {
            $each: {
                target: { ...forms.validations.genericRequired },
                sharePrice: { ...forms.validations.genericRequired },
                minimumInvestmentAmount: { ...forms.validations.genericRequired },
            },
        },
    },
    computed: {
        /**
         * @returns {InvestmentRoundDashboardTransformed[]}
         */
        allRounds() {
            return this.rounds || [];
        },
    },
    watch: {
        rounds() {
            this.buildRoundFields();
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
        buildRoundFields() {
            this.fields = [];
            for (const round of this.allRounds) {
                this.fields.push({
                    isActive: {
                        ...forms.fields.admin.estates.isActive,
                        value: round.isActive || false,
                    },
                    target: {
                        ...forms.fields.admin.estates.target,
                        value: round.target || 0,
                        isHalf: true,
                    },
                    sharePrice: {
                        ...forms.fields.admin.estates.sharePrice,
                        value: round.sharePrice || 0,
                        isDisabled: round?.issues?.some(issue => issue.balance),
                        isHalf: true,
                    },
                    minimumInvestmentAmount: {
                        ...forms.fields.admin.estates.minimalInvestmentAmount,
                        value: round.minimumInvestmentAmount || 0,
                    },
                });
                this.updateChangeCount(round.id);
            }
        },
        updateChangeCount(id) {
            if (!this.changeCount[id]) {
                this.changeCount[id] = 1;
            } else {
                this.changeCount[id] += 1;
            }
        },
        onFormUpdate(index, data, round) {
            this.$v.fields.$each[index].$touch();
            if (data.isActive.value === true && this.changeCount[round.id] >= 1) {
                this.fields.forEach((f, i) => {
                    if (i !== index && f.isActive.value) {
                        this.updateChangeCount(this.rounds[i].id);
                        f.isActive.value = false;
                    }
                });
            }
            this.fields.splice(index, 1, data);
            this.updateChangeCount(round.id);
        },
        save() {
            this.allRounds.forEach(async(round, i) => {
                this.$v.fields.$each[i].$touch();
                if (!this.$v.fields.$each[i].$invalid && this.changeCount[round.id] > 1) {
                    const { data, error } = await mainClient.estates.requests.updateEstateRound({
                        estateId: this.estate.id,
                        roundId: round.id,
                        // index: round.index,
                        target: this.fields[i].target.value,
                        share_price: this.fields[i].sharePrice.value,
                        minimum_investment_amount: this.fields[i].minimumInvestmentAmount.value,
                        is_active: this.fields[i].isActive.value,
                    });
                    if (error) {
                        this.error = '';
                        for (const errorKey in error) {
                            this.error += `${error[errorKey]?.toString()}.`;
                        } // TODO double code
                    }
                }
            });
            if (!this.error) {
                window.setTimeout(() => {
                    this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estate.id });
                }, 1500);
                this.close();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-edit-investment-round {
    .modal();

    display: flex;
    flex-direction: column;
    gap: 24px;

    height: 100%;
    overflow: hidden;
    padding-bottom: 40px;

    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 75%);
        &-container {
            width: 50px;
            padding-top: 8px;
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__heading {
        .typography-heading();
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;
        &-date {
            color: @color-gray-main;
        }
    }
    &__form {
        &-error {
            .typography-body();

            margin-top: 4px;

            color: @color-accent-negative;
        }
    }
    &__button {
        width: 206px;
        margin: 0 auto 24px;
    }
    &__cancel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        margin: 0 auto;

        color: @color-accent-negative;

        cursor: pointer;
        &-icon {
            margin-right: 10px;

            font-size: 2rem;
        }
        &-text {
            .typography-heading();
        }
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
