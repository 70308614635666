<template>
    <div class="modal-prepare-invest-offer-form">
        <div class="modal-prepare-invest-offer-form__row">
            <div class="modal-prepare-invest-offer-form__row-column">
                <div class="modal-prepare-invest-offer-form__row-column-title"
                    v-text="'Параметры'"
                />
                <ui-form
                    v-bind:model="fields"
                    v-bind:validation="$v"
                    v-bind:submit-handler="submitHandler"
                    v-on:update="updateFormData"
                />
            </div>
            <div class="modal-prepare-invest-offer-form__row-column">
                <div class="modal-prepare-invest-offer-form__row-column-title"
                    v-text="'Документы'"
                />
                <modal-prepare-invest-offer-form-files
                    v-bind:documents="documents"
                    v-bind:estate="estate"
                    v-bind:validations="$v"
                    v-on:update-document="updateDocumentHandler"
                />
            </div>
        </div>
        <div class="modal-prepare-invest-offer-form__button">
            <ui-button
                v-bind:disabled="($v.$dirty && $v.$invalid) || isLoading || isBlocked"
                v-on:click="submitHandler"
                theme="primary"
                >
                Отправить
            </ui-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'modal-prepare-invest-offer-form',
    props: {
        /**
         * @type {EstateEditGetTransformed}
         */
        estate: {
            type: Object,
        },
        /**
         * @type {IssueDashboardTransformed}
         */
        issue: {
            type: Object,
        },
        isBlocked: {
            type: Boolean,
        },
        price: {
            type: [ Number, String, null ],
        },
    },
    data: () => ({
        fields: {
            issueNumber: { ...forms.fields.admin.investOffer.issueNumber },
            issueDate: { ...forms.fields.admin.investOffer.issueDate, isAutofocus: true },
            investmentExpireDate: { ...forms.fields.admin.investOffer.investmentExpireDate },
            investmentAgreement: { ...forms.fields.admin.shares.shareInvestmentAgreement },
            // purpose: { ...forms.fields.admin.investOffer.purpose },
            preferenceSharesAmount: { ...forms.fields.admin.investOffer.preferenceSharesAmount },
            offerNumber: { ...forms.fields.admin.investOffer.offerNumber },
        },
        documents: [],
        smsCode: null,
    }),
    validations() {
        const result = {
            fields: {
                issueNumber: { ...forms.validations.genericRequired },
                issueDate: { ...forms.validations.date },
                investmentExpireDate: { ...forms.validations.date },
                investmentAgreement: { ...forms.validations.genericRequired },
                // purpose: { ...forms.validations.genericRequired },
                preferenceSharesAmount: { ...forms.validations.genericRequired },
                offerNumber: { ...forms.validations.genericRequired },
            },
        };
        if (this.documents.length > 0) {
            result.documents = {};
            this.documents.forEach(item => {
                result.documents[item.title] = (documents) => {
                    const document = documents.find(doc => doc.title === item.title);
                    return !!document?.value && typeof document.value === 'string';
                };
            });
        }
        return result;
    },
    computed: {
        ...mapState('admin/investments', [
            'generateInvestmentOfferDocumentsResult',
            'generateInvestmentOfferDocumentsIsLoading',
            'generateInvestmentOfferDocumentsError',

            'calculateSharesIsLoading',
        ]),
        user() {
            return this.$store.getters['users/user'];
        },
        isLoading() {
            return this.generateInvestmentOfferDocumentsIsLoading ||
                this.calculateSharesIsLoading;
        },
    },
    methods: {
        updateFormData(data) {
            this.fields = data;
        },
        updateDocumentHandler({ title, value }) {
            this.documents = this.documents.map(item => {
                if (item.title === title) {
                    return {
                        ...item,
                        value,
                    };
                }
                return item;
            });
        },
        setCode(code) {
            this.smsCode = code;
            this.submitHandler();
        },
        submitHandler() {
            if (this.isLoading || this.isBlocked) {
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (!this.smsCode) {
                this.$store.commit('modals/push', {
                    name: 'sms-input',
                    props: {
                        smsVerificationProps: {
                            isRequestRequired: true,
                            phone: this.user.phone,
                            customText: 'На ваш номер телефона мы выслали код подтверждения - это ваша подпись.',
                        },
                    },
                    on: {
                        submit: this.setCode,
                    },
                });
                return;
            }
            this.$store.dispatch('admin/investments/generateInvestmentOfferDocuments', {
                value: this.user.phone,
                code: this.smsCode,
                id: this.estate.id,
                issueId: this.issue.id,
                issueDate: this.fields.issueDate.value,
                issueNumber: this.fields.issueNumber.value,
                investmentExpireDate: this.fields.investmentExpireDate.value,
                investmentAgreement: this.fields.investmentAgreement.value,
                // purpose: this.fields.purpose.value,
                preferenceSharesAmount: this.fields.preferenceSharesAmount.value,
                price: this.price,
                offerNumber: this.fields.offerNumber.value,
            });
        },
    },
    beforeMount() {
        const documentBase = {
            id: null,
            title: 'Документ',
            url: '',
            slug: '',
            value: null,
            initialValue: null,
            accept: '.docx, .doc',
            errors: {
                required: 'Загрузите файл шаблона',
            },
        };
        const result = this.estate.templates.map(template => ({
            ...documentBase,
            ...template,
            value: template.url,
            initialValue: template,
        }));
        result.push({
            ...documentBase,
            title: 'ДСУР',
            ...(this.estate.dsur ? this.estate.dsur : {}),
            value: this.estate.dsur ? this.estate.dsur.url : null,
            initialValue: this.estate.dsur,
            accept: '.docx, .doc, .pdf',
            errors: {
                required: 'Загрузите файл ДСУР',
            },
        });
        this.documents = result;
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-prepare-invest-offer-form {
    &__row {
        display: flex;
        width: 100%;
        gap: 24px;
        margin-bottom: 40px;
        &-column {
            flex: 0 0 auto;
            width: 50%;
            &-title {
                .typography-small();

                margin-bottom: 24px;
            }
        }
    }
    &__button {
        width: 206px;
        margin: 0 auto;
    }
    @media @media-sm-down {
        &__row {
            flex-direction: column;
            &-column {
                width: 100%;
            }
        }
    }
}
</style>
