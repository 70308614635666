import utils from '~/utils';
import { mainClient } from '~/network';

const baseState = {
    givenEstate: null,
    estateId: null,
    currentStepIndex: 0,
    newValues: {},
    isTouched: false,

    getFormDataIsLoading: false,
    getFormDataError: null,
    getFormDataResult: null,

    createFormDataIsLoading: false,
    createFormDataError: null,
    createFormDataResult: null,

    updateFormDataIsLoading: false,
    updateFormDataError: null,
    updateFormDataResult: null,

    getAuthUrlIsLoading: false,
    getAuthUrlError: null,
    getAuthUrlResult: null,

    getBankInfoIsLoading: false,
    getBankInfoError: null,
    getBankInfoResult: null,

    sendOtpIsLoading: false,
    sendOtpError: null,
    sendOtpResult: null,

    sendFormIsLoading: false,
    sendFormError: null,
    sendFormResult: null,

    bikDependentFieldIds: [ 'bank_inn', 'bank', 'bank_address', 'ks' ],
};

const state = { ...baseState };

const getters = {
    steps(state) {
        if (!state.getFormDataResult) {
            return [];
        }
        return state.getFormDataResult.form.template.stages;
    },
    currentStep(state, getters) {
        if (!getters.steps.length) {
            return null;
        }
        return getters.steps[state.currentStepIndex];
    },
    currentBik(state) {
        return state.newValues.bik || state.getFormDataResult?.form.values.bik?.value;
    },
    currentLs(state) {
        return state.newValues.ls || state.getFormDataResult?.form.values.ls?.value;
    },
    lsByBikError(state) {
        return state.updateFormDataError && (utils.network.parseError(state.updateFormDataError, true).indexOf('ls does not match bik') > -1);
    },
    isAuthorized(state) {
        return state.getFormDataResult && state.getFormDataResult.apiState !== null;
    },
};

const actions = {
    async getFormData({ state }, { onSuccess = null, onError = null, id = null } = {}) {
        if (state.getFormDataIsLoading || (!state.estateId && !id)) {
            return;
        }
        state.getFormDataIsLoading = true;
        const { data, error } = await mainClient.status.requests.getFormData({ id: id || state.estateId });
        if (error) {
            state.getFormDataError = error;
            state.getFormDataResult = null;
            if (onError) {
                onError(error);
            }
        } else if (data) {
            state.getFormDataResult = data;
            state.getFormDataError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getFormDataIsLoading = false;
    },
    async createFormData({ state }, { onSuccess = null, onError = null, id = null } = {}) {
        if (state.createFormDataIsLoading || (!state.estateId && !id)) {
            return;
        }
        state.createFormDataIsLoading = true;
        const { data, error } = await mainClient.status.requests.createFormData({ id: id || state.estateId });
        if (error) {
            state.createFormDataError = error;
            state.createFormDataResult = null;
            if (onError) {
                onError(error);
            }
        } else if (data) {
            state.createFormDataResult = data;
            state.createFormDataError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.createFormDataIsLoading = false;
    },
    async updateFormData({ state }, { onSuccess = null, ...payload }) {
        if (state.updateFormDataIsLoading) {
            return;
        }
        state.updateFormDataIsLoading = true;
        const { data, error } = await mainClient.status.requests.updateFormData(payload);
        if (error) {
            state.updateFormDataError = error;
            state.updateFormDataResult = null;
        } else if (data) {
            state.updateFormDataResult = data;
            state.updateFormDataError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.updateFormDataIsLoading = false;
    },
    // Debug request version
    // async getAuthUrl({ state }, { onSuccess = null, id = null } = {}) {
    //     if (state.getAuthUrlIsLoading) {
    //         return;
    //     }
    //     state.getAuthUrlIsLoading = true;
    //     setTimeout(() => {
    //         const data = { url: 'https://www.google.com/' };
    //         state.getAuthUrlResult = data;
    //         state.getAuthUrlError = null;
    //         if (onSuccess) {
    //             onSuccess(data);
    //         }
    //         state.getAuthUrlIsLoading = false;
    //     }, 3000);
    // },
    async getAuthUrl({ state }, { onSuccess = null, id = null } = {}) {
        if (state.createFormDataIsLoading || (!state.estateId && !id && !state.givenEstate)) {
            return;
        }
        state.getAuthUrlIsLoading = true;
        const { data, error } = await mainClient.status.requests.getAuthUrl({ id: id ?? state.estateId ?? state.givenEstate });
        if (error) {
            state.getAuthUrlError = error;
            state.getAuthUrlResult = null;
        } else if (data) {
            state.getAuthUrlResult = data;
            state.getAuthUrlError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getAuthUrlIsLoading = false;
    },
    async getBankInfo({ state }, { onSuccess = null, bik } = {}) {
        if (state.getBankInfoIsLoading) {
            return;
        }
        state.getBankInfoIsLoading = true;
        const { data, error } = await mainClient.status.requests.getBankInfo({ bik });
        if (error) {
            state.getBankInfoError = error;
            state.getBankInfoResult = null;
        } else if (data) {
            state.getBankInfoResult = data;
            state.getBankInfoError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getBankInfoIsLoading = false;
    },
    async sendOtp({ state }, { onSuccess = null, onError = null, id, phone } = {}) {
        if (state.sendOtpIsLoading) {
            return;
        }
        state.sendOtpIsLoading = true;
        const { data, error } = await mainClient.status.requests.sendOtp({ id, phone });
        if (error) {
            state.sendOtpError = error;
            state.sendOtpResult = null;
            if (onError) {
                onError(error);
            }
        } else if (data) {
            state.sendOtpResult = data;
            state.sendOtpError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.sendOtpIsLoading = false;
    },
    async sendForm({ state }, { onSuccess = null, onError = null, id } = {}) {
        if (state.sendFormIsLoading) {
            return;
        }
        state.sendFormIsLoading = true;
        const { data, error } = await mainClient.status.requests.sendForm({ id });
        if (error) {
            state.sendFormError = error;
            state.sendFormResult = null;
            if (onError) {
                onError(error);
            }
        } else if (data) {
            state.sendFormResult = data;
            state.sendFormError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.sendFormIsLoading = false;
    },
};

const mutations = {
    reset(state) {
        Object.keys(baseState).forEach(key => {
            state[key] = baseState[key];
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
