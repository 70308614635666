var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"common-file-loader",class:{
        _loaded: _vm.isLoaded,
        _loading: _vm.isLoading,
        _error: _vm.error !== null,
    },attrs:{"for":_vm.inputId},on:{"drop":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDrop.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"common-file-loader__loader",class:{
            _instant: _vm.file === null,
        },style:(_vm.loaderStyle)}),(_vm.file === null && !_vm.error)?_c('div',{staticClass:"common-file-loader__empty"},[_c('div',{staticClass:"common-file-loader__empty-icon"},[_c('icon',{attrs:{"name":"plus"}})],1),(_vm.text)?_c('div',{staticClass:"common-file-loader__empty-text",domProps:{"textContent":_vm._s(_vm.text)}}):_vm._e()]):_c('div',{staticClass:"common-file-loader__info"},[_c('div',{staticClass:"common-file-loader__info-title",domProps:{"textContent":_vm._s(_vm.file ? _vm.file.name : _vm.innerName)}}),_c('div',{staticClass:"common-file-loader__info-status",domProps:{"textContent":_vm._s(_vm.status)}}),(!_vm.isLoading)?_c('div',{staticClass:"common-file-loader__info-remove",on:{"click":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c('icon',{attrs:{"name":"cross-round"}})],1):_vm._e()]),_c('input',{ref:"input",staticClass:"common-file-loader__input",attrs:{"id":_vm.inputId,"type":"file","disabled":_vm.isLoading},on:{"change":_vm.onFileChange}})])
}
var staticRenderFns = []

export { render, staticRenderFns }