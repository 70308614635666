<template>
    <div class="modal-status-form-success">
        <div class="modal-status-form-success__title"
            v-text="'Форма успешно отправлена!'"
        />
        <!-- <div class="modal-status-form-success__description">
            описание
        </div> -->
        <div class="modal-status-form-success__button">
            <ui-button
                v-on:click="close"
                >
                Закрыть
            </ui-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-status-form-success',
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-success {
    &__title {
        .typography-heading();

        margin-bottom: 24px;

        text-align: center;
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;

        text-align: center;
        &._error {
            color: @color-accent-negative;
        }
    }
    &__code {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__button {
        width: fit-content;
        margin: 0 auto;
    }
}
</style>
