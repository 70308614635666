var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-progress",class:[
        `_theme_${_vm.theme}`,
        {
            _empty: !_vm.value || _vm.value <= 0,
            _enlarged: _vm.isEnlarged,
            _minimum: _vm.value > 0,
        },
    ]},[_c('div',{staticClass:"ui-progress__indicator",style:(_vm.style)})])
}
var staticRenderFns = []

export { render, staticRenderFns }