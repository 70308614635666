import { render, staticRenderFns } from "./cabinet-requests-list-secondary-item.vue?vue&type=template&id=88597c94&scoped=true"
import script from "./cabinet-requests-list-secondary-item.vue?vue&type=script&lang=js"
export * from "./cabinet-requests-list-secondary-item.vue?vue&type=script&lang=js"
import style0 from "./cabinet-requests-list-secondary-item.vue?vue&type=style&index=0&id=88597c94&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88597c94",
  null
  
)

export default component.exports