export const getFormData = (data) => {
    const result = {
        apiState: data.api_state,
        form: {
            template: data.form_data,
            values: data.user_data,
        },
    };

    result.form.template.stages.forEach((stage) => {
        stage.fields.forEach((field) => {
            const fieldData = result.form.template.data[field.id];
            field.template = fieldData.template;
            if (field.mutateFields) {
                const additionalMutations = [];
                field.mutateFields = field.mutateFields.map((x) => {
                    if (x.mutate === undefined) {
                        additionalMutations.push({ ...x, value: !x.value, mutate: { visible: true } });
                        return { ...x, mutate: { visible: false } };
                    }
                    return x;
                });
                field.mutateFields = [...field.mutateFields, ...additionalMutations];
            }
            if (fieldData?.options) {
                field.options = Array.isArray(fieldData.options) ? fieldData.options : fieldData.options.split(',');
                field.options = field.options.map((option) => ({
                    text: option,
                    value: option,
                }));
                field.type = `Select with ${fieldData.options.length} options`;
            } else if (fieldData?.mask) {
                field.type = `${fieldData?.mask}-field`;
            } else {
                field.type = fieldData?.type || 'TextField';
            }
            const valueData = result.form.values[field.id];
            field.value = !valueData ? 'valueless' : (valueData?.value || null);
            // eslint-disable-next-line camelcase
            field.isHidden = valueData?.is_hidden || false;
            // eslint-disable-next-line camelcase
            field.isDisabled = valueData?.read_only || false;
            if (field.validate) {
                field.validations = field.validate;
            } else {
                field.validations = null;
            }
        });
    });

    return result;
};

export const createFormData = (data) => {
    const result = {
        ...data,
    };
    return result;
};

export const getAuthUrl = (data) => {
    const result = {
        ...data,
    };
    return result;
};

// eslint-disable-next-line camelcase
// export const getBankInfo = ({ bank_inn, bank, bank_address, ks }) => {
//     const result = {
//         inn: bank_inn,
//         title: bank,
//         address: bank_address,
//         ks,
//     };
//     return result;
// };

export const getBankInfo = (data) => {
    return data;
};
