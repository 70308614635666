<template>
    <section class="page-admin-estates-item-info">
        <div class="page-admin-estates-item-info__block"
            v-for="(item, i) in blocks"
            v-bind:key="i"
            >
            <page-admin-estates-item-info-block
                v-bind:title="item.title"
                >
                <component
                    v-bind:is="'page-admin-estates-item-info-' + item.component"
                    v-bind:fields="item.fields"
                    v-bind:validation="$v"
                    v-on:update="updateFormData"
                    v-on:submit="submitFormData"
                />
            </page-admin-estates-item-info-block>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-admin-estates-item-info',
    validations() {
        const result = {
            fields: {
                // general image
                image: { ...forms.validations.genericRequired },
                // general top
                title: { ...forms.validations.genericRequired },
                type: { ...forms.validations.genericRequired },
                address: { ...forms.validations.genericRequired },
                description: { ...forms.validations.genericRequired },
                // general bottom
                totalValue: { ...forms.validations.genericRequired },
                // currentDividendProfitability: { ...forms.validations.genericRequired },
                targetDividendProfitability: { ...forms.validations.genericRequired },
                yearPercent: { ...forms.validations.genericRequired },
                // maxLoanTerm: { ...forms.validations.genericRequired },
                capRate: { ...forms.validations.genericRequired },
                totalSquare: { ...forms.validations.genericRequired },
                rentableSquare: { ...forms.validations.genericRequired },
                occupancy: { ...forms.validations.genericRequired },
                // minimalInvestmentAmount: { ...forms.validations.genericRequired },
                tradeMinInvestmentAmount: { ...forms.validations.genericRequired },
                annualIndexing: { ...forms.validations.genericRequired },
            },
        };
        if (this.fields.document.value !== null && typeof this.fields.document.value !== 'string') {
            result.fields.documentTitle = { ...forms.validations.genericRequired };
        }
        if (this.fields.maxLoanTerm.value) {
            result.fields.maxLoanTerm = { ...forms.validations.date };
        }
        return result;
    },
    /**
     * @property {Object} computed
     * @property {EstateEditGetTransformed} computed.getEstateInfoResult
     */
    computed: {
        ...mapState('estates', {
            getEstateTypesResult: state => state.getEstateTypesResult,
        }),
        ...mapState('admin/estates', {
            getEstateInfoResult: state => state.getEstateInfoResult,
            // general image
            image: state => state.getEstateInfoResult.image,
            // general top
            title: state => state.getEstateInfoResult.title,
            type: state => state.getEstateInfoResult.type,
            address: state => state.getEstateInfoResult.address,
            description: state => state.getEstateInfoResult.description,
            // general bottom
            totalValue: state => state.getEstateInfoResult.totalValue,
            // currentDividendProfitability: state => state.getEstateInfoResult.currentDividendProfitability,
            targetDividendProfitability: state => state.getEstateInfoResult.targetDividendProfitability,
            // target: state => state.getEstateInfoResult.target,
            yearPercent: state => state.getEstateInfoResult.yearPercent,
            capRate: state => state.getEstateInfoResult.capRate,
            totalSquare: state => state.getEstateInfoResult.totalSquare,
            rentableSquare: state => state.getEstateInfoResult.rentableSquare,
            occupancy: state => state.getEstateInfoResult.occupancy,
            // minimalInvestmentAmount: state => state.getEstateInfoResult.minimalInvestmentAmount,
            tradeMinInvestmentAmount: state => state.getEstateInfoResult.tradeMinInvestmentAmount,
            initialBalance: state => state.getEstateInfoResult.initialBalance,
            annualIndexing: state => state.getEstateInfoResult.annualIndexing,
            info: state => state.getEstateInfoResult.info,
            // document
            documentTitle: state => state.getEstateInfoResult.documentTitle,
            document: state => state.getEstateInfoResult.document,
            // activity
            isActive: state => state.getEstateInfoResult.isActive,
        }),
        fields: {
            get() {
                const fields = {
                    // general image
                    image: { ...forms.fields.admin.estates.image, value: this.image },
                    // general top
                    title: { ...forms.fields.admin.estates.title, value: this.title },
                    type: {
                        ...forms.fields.admin.estates.type,
                        value: this.type.id,
                        options: this.getEstateTypesResult.map(x => ({ value: x.id, text: x.title })),
                    },
                    address: { ...forms.fields.admin.estates.address, value: this.address },
                    description: { ...forms.fields.admin.estates.description, value: this.description },
                    // general bottom
                    totalValue: { ...forms.fields.admin.estates.totalValue, value: this.totalValue, isHalf: this.isDesktop },
                    // currentDividendProfitability: { ...forms.fields.admin.estates.currentDividendProfitability, value: this.currentDividendProfitability, isHalf: this.isDesktop },
                    targetDividendProfitability: { ...forms.fields.admin.estates.targetDividendProfitability, value: this.targetDividendProfitability, isHalf: this.isDesktop },
                    // target: { ...forms.fields.admin.estates.target, value: this.target, isHalf: this.isDesktop },
                    yearPercent: { ...forms.fields.admin.estates.yearPercent, value: this.yearPercent, isHalf: this.isDesktop },
                    maxLoanTerm: { ...forms.fields.admin.estates.maxLoanTerm, value: this.info.maxLoanTerm, isHalf: this.isDesktop },
                    capRate: { ...forms.fields.admin.estates.capRate, value: this.capRate, isHalf: this.isDesktop },
                    totalSquare: { ...forms.fields.admin.estates.totalSquare, value: this.totalSquare, isHalf: this.isDesktop },
                    rentableSquare: { ...forms.fields.admin.estates.rentableSquare, value: this.rentableSquare, isHalf: this.isDesktop },
                    occupancy: { ...forms.fields.admin.estates.occupancy, value: this.occupancy, isHalf: this.isDesktop },
                    // minimalInvestmentAmount: { ...forms.fields.admin.estates.minimalInvestmentAmount, value: this.minimalInvestmentAmount, isHalf: this.isDesktop },
                    tradeMinInvestmentAmount: { ...forms.fields.admin.estates.tradeMinInvestmentAmount, value: this.tradeMinInvestmentAmount, isHalf: this.isDesktop },
                    initialBalance: { ...forms.fields.admin.estates.initialBalance, value: this.initialBalance, isHalf: this.isDesktop },
                    annualIndexing: { ...forms.fields.admin.estates.annualIndexing, value: this.annualIndexing, isHalf: this.isDesktop },
                    shareCapital: { ...forms.fields.admin.estates.shareCapital, value: this.info.shareCapital, isHalf: this.isDesktop },
                    // document
                    documentTitle: { ...forms.fields.admin.estates.documentTitle, value: this.documentTitle, isHalf: this.isDesktop },
                    document: { ...forms.fields.admin.estates.document, value: this.document, isHalf: this.isDesktop },
                    // activity
                    isActive: { ...forms.fields.admin.estates.isActive, value: this.isActive },
                };
                return fields;
            },
            set(newData) {
                const data = { ...this.getEstateInfoResult };
                for (const [key, field] of Object.entries(newData)) {
                    if (key === 'type') {
                        const type = this.getEstateTypesResult.find(x => x.id.toString() === field.value.toString());
                        if (type) {
                            data[key] = type;
                        }
                    } else if (key === 'shareCapital' || key === 'maxLoanTerm') {
                        data.info[key] = field.value;
                    } else {
                        data[key] = field.value;
                    }
                }
                this.$store.commit('admin/estates/setUpdateEstateInfoIsSaved', false);
                this.$store.commit('admin/estates/setUpdateEstateInfoInfoIsButtonActive', true);
                this.$store.commit('admin/estates/setEstateInfo', data);
            },
        },
        isDesktop() {
            return window.innerWidth >= 768;
        },
        blocks() {
            const general = {
                component: 'general',
                title: 'Общая информация',
                fields: {
                    // image
                    image: this.fields.image,
                    // top
                    title: this.fields.title,
                    type: this.fields.type,
                    address: this.fields.address,
                    description: this.fields.description,
                    totalValue: this.fields.totalValue,
                    // bottom
                    // currentDividendProfitability: this.fields.currentDividendProfitability,
                    // target: this.fields.target,
                    targetDividendProfitability: this.fields.targetDividendProfitability,
                },
            };
            if (this.getEstateInfoResult.isLoanInvestmentTypeAvailable) {
                general.fields = {
                    ...general.fields,
                    yearPercent: this.fields.yearPercent,
                    maxLoanTerm: this.fields.maxLoanTerm,
                };
            }
            general.fields = {
                ...general.fields,
                capRate: this.fields.capRate,
                totalSquare: this.fields.totalSquare,
                rentableSquare: this.fields.rentableSquare,
                occupancy: this.fields.occupancy,
                tradeMinInvestmentAmount: this.fields.tradeMinInvestmentAmount,
                initialBalance: this.fields.initialBalance,
                annualIndexing: this.fields.annualIndexing,
                shareCapital: this.fields.shareCapital,
            };
            const document = {
                component: 'document',
                title: 'Документы',
                fields: {
                    documentTitle: this.fields.documentTitle,
                    document: this.fields.document,
                },
            };
            const activity = {
                component: 'activity',
                title: 'Активность',
                fields: {
                    isActive: this.fields.isActive,
                },
            };
            return [
                general,
                document,
                activity,
            ];
        },
        isInvalid() {
            return this.$v.$invalid;
        },
        isTouched() {
            return this.$v.$dirty;
        },
        isSaved() {
            return this.$store.state.admin.estates.updateEstateInfoIsSaved;
        },
        isTouchNeeded() {
            return this.$store.state.admin.estates.updateEstateInfoIsTouchNeeded;
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = { ...this.fields, ...newData };
        },
        submitFormData() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$store.dispatch('admin/estates/updateEstateInfo');
            }
        },
    },
    watch: {
        isInvalid: {
            immediate: true,
            handler(newVal) {
                this.$emit('set-validity', !newVal);
                this.$store.commit('admin/estates/setUpdateEstateInfoIsInvalid', newVal);
            },
        },
        isTouched: {
            immediate: true,
            handler(newVal) {
                this.$store.commit('admin/estates/setUpdateEstateInfoIsTouched', newVal);
            },
        },
        isTouchNeeded: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                    this.$store.commit('admin/estates/setUpdateEstateInfoIsTouchNeeded', false);
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-info {
    &__block {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 40px;
        }
    }
}
</style>
