import client from '../_client';
import config from '~/config';
import transforms from './transforms';
import utils from '~/utils';
import '~/types/api';

export const getItems = (params = {}) => {
    return client.request({
        url: config.api.estates.items,
        method: 'GET',
        params,
        transformData: transforms.responses.list,
    });
};

export const getItem = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estates.items + id + '/',
        method: 'GET',
        params,
        transformData: transforms.responses.item,
    });
};

export const getTypes = (params = {}) => {
    return client.request({
        url: config.api.estates.types,
        method: 'GET',
        params,
        transformData: transforms.responses.typesList,
    });
};

export const getChartSharesData = ({ id, ...params } = {}) => {
    return client.request({
        url: config.api.estates.chartData.shares.replace('{id}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.chartData,
    });
};

export const getChartDividendsData = ({ id, ...params } = {}) => {
    return client.request({
        url: config.api.estates.chartData.dividends.replace('{id}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.chartData,
    });
};

export const getAdminEstateInfo = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estatesAdmin.estate + id + '/',
        method: 'GET',
        params,
        transformData: transforms.responses.adminEstateItem,
    });
};

export const getAdminEstateShares = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estatesAdmin.shares.replace('{id}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.adminEstateSharesList,
    });
};

export const createEstate = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.estate,
        method: 'POST',
        data: transforms.requests.adminEstateItem(data),
        transformData: transforms.responses.adminEstateItem,
    });
};

export const removeEstate = (data = { id: -1 }) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.estate + id + '/',
        method: 'DELETE',
        // data: transforms.requests.adminEstateItem(data),
        // transformData: transforms.responses.adminEstateItem,
    });
};

export const updateAdminEstateInfo = (data = { id: -1 }) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.estate + id + '/',
        method: 'PATCH',
        data: transforms.requests.adminEstateItem(data),
        transformData: transforms.responses.adminEstateItem,
    });
};
/**
 * Создание Выпуска в Раунде инвестирования.
 * @param {Object} data
 * @param {number} data.roundId - Идентификатор Раунда.
 * @returns {Promise<{ data: IssueDashboard, error: string|null, status: number }>}
 */
export const createAdminIssue = (data = { roundId: -1 }) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.issues,
        method: 'POST',
        data: {
            investment_round: data.roundId,
        },
        // transformData: transforms.responses.adminEstateItem,
    });
};
/**
 * Обновление объекта Выпуска.
 * @param {Object} data
 * @param {string|number} id
 * @param {string} data.status
 * @returns {Promise<{ data: Issue, error: string|null, status: number }|null> | null}
 */
export const updateAdminIssueInfo = ({ id, ...data }) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.issue.replace('{id}', id),
        method: 'PATCH',
        data: transforms.requests.adminIssueItem(data),
        // transformData: transforms.responses.adminEstateItem,
    });
};

// export const patchAdminEstateInfo = (data = { id: -1 }) => {
//     const id = data.id;
//     delete data.id;
//     return client.request({
//         headers: {
//             'X-CSRFToken': utils.network.getCSRFToken(),
//         },
//         url: config.api.estatesAdmin.estate + id + '/',
//         method: 'PATCH',
//         data: transforms.requests.adminEstateItem(data),
//         transformData: transforms.responses.adminEstateItem,
//     });
// };

/**
 * Получение Раундов инвестирования для недвижимости.
 * @param {Object} data
 * @param {string} data.id - Идентификатор недвижимости.
 * @returns {Promise<{ data: GetDashboardEstateRoundsResponseData, error: string|null, status: number }|null>}
 */
export const getAdminEstateRounds = ({ id, ...params } = {}) => {
    const url = config.api.estatesAdmin.rounds.replace('{id}', id);
    return client.request({
        url,
        method: 'GET',
        params,
        transformData: transforms.responses.adminEstateRoundsList,
    });
};

export const getAdminEstateRequests = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    const url = config.api.estatesAdmin.requests.replace('{id}', id);
    return client.request({
        url,
        method: 'GET',
        params: transforms.requests.adminEstateRequestsList(params),
        transformData: transforms.responses.adminEstateRequestsList,
    });
};
/**
 * Создание Заявки инвестирования.
 * @param {Object} data
 * @param {string} data.estateId - Идентификатор недвижимости.
 * @param {string} data.userId - Идентификатор пользователя.
 * @param {string} data.issueId - Идентификатор выпуска.
 * @param {number} data.shareQuantity - Количество акций.
 * @returns {Promise<{ data: DashboardInvestmentRequest, error: string|null, status: number } | null> | null}
 */
export const createEstateInvestRequest = (data = {}) => {
    const estateId = data.estateId || -1;
    const url = config.api.estatesAdmin.requests.replace('{id}', estateId);
    const payload = {
        user: data.userId,
        estate: data.estateId,
        issue: data.issueId,
        share_quantity: data.shareQuantity,
    };
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url,
        method: 'POST',
        data: payload,
    });
};
/**
 * Отмена не подписанных Заявок.
 * @param {Object} params
 * @param {string} params.estateId - Идентификатор недвижимости.
 * @param {string} params.requestId - Идентификатор заявки.
 * @returns {Promise<{ data: {}, error: string|null, status: number } | null> | null}
 */
export const cancelEstateInvestRequest = (params = { estateId: -1, requestId: -1 }) => {
    const estateId = params.estateId;
    const requestId = params.requestId;
    delete params.estateId;
    delete params.requestId;
    const url = config.api.estatesAdmin.cancelRequest.replace('{estateId}', estateId).replace('{requestId}', requestId);
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url,
        method: 'POST',
        params,
        transformData: transforms.responses.adminEstateRequestsListItem, // TODO ?
    });
};

export const editEstateInvestRequest = (data = { estateId: -1, requestId: -1 }) => {
    const estateId = data.estateId;
    const requestId = data.requestId;
    delete data.estateId;
    delete data.requestId;
    const url = config.api.estatesAdmin.editRequest.replace('{estateId}', estateId).replace('{requestId}', requestId);
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url,
        method: 'PATCH',
        data,
    });
};
/**
 * Создание Раунда инвестиций.
 * @param {Object} data
 * @param {string} data.estateId - Идентификатор недвижимости.
 * @param {string} data.isActive - Активность раунда инвестиций.
 * @param {string} data.minimumInvestmentAmount - - Минимальная сумма инвестиций, которую можно внести в раунд.
 * @param {string} data.target - Цель раунда инвестиций. Это положительное целое число. Задает общую сумму, которую должен собрать раунд.
 * @param {number} data.sharePrice - Цена акции в раунде инвестиций, задается в десятичном формате в виде строки.
 * @returns {Promise<{ data: InvestmentRoundDashboardCreate, error: string|null, status: number } | null> | null}
 */
export const createEstateRound = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.rounds.replace('{id}', data.estateId),
        method: 'POST',
        data: {
            is_active: data.isActive,
            minimum_investment_amount: data.minimumInvestmentAmount,
            target: data.target,
            share_price: data.sharePrice,
        },
        // data: transforms.requests.adminEstateItem(data),
        // transformData: transforms.responses.adminEstateItem,
    });
};
/**
 * Обновление Раунда инвестиций.
 * @param {Object} data
 * @param {string} estateId - Идентификатор недвижимости.
 * @param {string} roundId - Идентификатор недвижимости.
 * @param {string} [data.index] - Индекс раунда.
 * @param {string} [data.is_active] - Активность раунда инвестиций.
 * @param {string} [data.minimum_investment_amount] - Минимальная сумма инвестиций, которую можно внести в раунд.
 * @param {string} [data.target] - Цель раунда инвестиций. Это положительное целое число. Задает общую сумму, которую должен собрать раунд.
 * @param {number} [data.share_price] - Цена акции в раунде инвестиций, задается в десятичном формате в виде строки.
 * @returns {Promise<{ data: InvestmentRoundDashboard, error: string|null, status: number } | null> | null}
 */
export const updateEstateRound = ({ estateId, roundId, ...data }) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.round.replace('{estateId}', estateId).replace('{roundId}', roundId),
        method: 'PATCH',
        data: data,
        // data: {
        //     index: data.index,
        //     is_active: data.isActive,
        //     minimum_investment_amount: data.minimumInvestmentAmount,
        //     target: data.target,
        //     share_price: data.sharePrice,
        // }, // TODO
        // data: transforms.requests.adminEstateItem(data),
        // transformData: transforms.responses.adminEstateItem,
    });
};

export const renderLoanOffer = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        responseType: 'arraybuffer',
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.renderLoanOffer.replace('{id}', id),
        method: 'POST',
        data: data.formData,
    });
};

export const createLoan = (data = {}) => {
    // const id = data.id;
    // delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.loan.create,
        // .loansList.replace('{id}', id),
        method: 'POST',
        data,
        // data: data.formData,
    });
};

/**
 * Получает данные о займе
 * @param {Object} [params]
 * @param {number} params.id
 * @returns {Promise<{ data: Loan, error: string|null, status: number } | null> | null}
 */
export const getLoan = (params = {}) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estatesAdmin.lastLoan.replace('{id}', id),
        method: 'GET',
        params,
    });
};

export const cancelLoan = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.loan.cancel.replace('{id}', id),
        method: 'POST',
        data,
    });
};

export const resolveLoan = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.loan.resolve.replace('{id}', id),
        method: 'POST',
        data,
    });
};

export const getLoanInvestmentRequests = (params = {}) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estatesAdmin.loan.investmentRequests.replace('{id}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.adminEstateRequestsList,
    });
};

export const uploadDocument = (payload = {}) => {
    const url = payload.document.title === 'ДСУР'
        ? config.api.estatesAdmin.uploadDocument.dsur.replace('{id}', payload.estate.id)
        : config.api.estatesAdmin.uploadDocument.template.replace('{id}', payload.document.id).replace('{estateId}', payload.estate.id);
    const data = new FormData();
    data.append('file', payload.file);
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url,
        method: 'POST',
        data,
        transformData: transforms.responses.adminEstateItemDocument,
    });
};
