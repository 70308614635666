var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"plugin-documents",class:{ _archived: _vm.isArchived }},[(_vm.displayMode === 'list')?_c('h2',{staticClass:"plugin-documents__title",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_c('div',{staticClass:"plugin-documents__list",class:{ _list: _vm.displayMode === 'list' }},_vm._l((_vm.items),function(item,index){return _c('a',{key:index,staticClass:"plugin-documents__list-item",attrs:{"href":item.url,"target":"_blank"}},[(_vm.displayMode === 'cards')?_c('ui-card-base',_vm._b({},'ui-card-base',{
                    withBorder: true,
                    paddingTop: '11px',
                    paddingRight: '24px',
                    paddingBottom: '11px',
                    paddingLeft: '24px',
                },false),[_c('plugin-documents-item',{attrs:{"item":item}})],1):_c('plugin-documents-item',{attrs:{"item":item}})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }