<template>
    <ui-card-base>
        <div class="page-cabinet-deals-item">
            <div class="page-cabinet-deals-item__headline">
                <div class="page-cabinet-deals-item__headline-image">
                    <common-image
                        v-bind:image="image"
                        v-bind:href="item.estate.url"
                    />
                </div>
                <div class="page-cabinet-deals-item__headline-info">
                    <div class="page-cabinet-deals-item__headline-info-top">
                        <div class="page-cabinet-deals-item__headline-info-top-status">
                            <ui-label
                                v-bind:title="statusTitle"
                                v-bind:theme="statusTheme"
                                is-uppercase
                            />
                        </div>
                        <div class="page-cabinet-deals-item__headline-info-top-document"
                            v-if="document"
                            >
                            <ui-trigger
                                v-bind:href="document.url"
                                text="Договор купли-продажи"
                                icon-left="download"
                                variant="dark"
                                is-underline-inverted
                                is-uppercase
                            />
                        </div>
                    </div>
                    <div class="page-cabinet-deals-item__headline-info-estate"
                        v-text="item.estate.title"
                    />
                </div>
            </div>
            <div class="page-cabinet-deals-item__botline">
                <div class="page-cabinet-deals-item__botline-stats">
                    <div class="page-cabinet-deals-item__botline-stats-item"
                        v-for="(stat, i) in stats"
                        v-bind:key="i"
                        >
                        <div class="page-cabinet-deals-item__botline-stats-item-title"
                            v-text="stat.title"
                        />
                        <div class="page-cabinet-deals-item__botline-stats-item-value"
                            v-text="stat.value"
                        />
                    </div>
                </div>
                <div class="page-cabinet-deals-item__botline-actions"
                    v-if="isStatusFormRequired || isSignAvailable || isCancelAvailable"
                    >
                    <div class="page-cabinet-deals-item__botline-actions-item"
                        v-if="isStatusFormRequired"
                        >
                        <ui-button
                            theme="primary"
                            v-on:click="statusFormHandler"
                            >
                            Заполнить форму Статуса
                        </ui-button>
                    </div>
                    <div class="page-cabinet-deals-item__botline-actions-item"
                        v-if="isSignAvailable"
                        >
                        <ui-button
                            theme="primary"
                            v-on:click="signHandler"
                            >
                            Подтвердить
                        </ui-button>
                    </div>
                    <div class="page-cabinet-deals-item__botline-actions-item"
                        v-if="isCancelAvailable"
                        >
                        <ui-trigger type="button"
                            v-on:click="cancelHandler"
                            theme="danger"
                            is-uppercase
                            >
                            Отменить
                        </ui-trigger>
                    </div>
                </div>
            </div>
            <div class="page-cabinet-deals-item__document"
                v-if="document"
                >
                <ui-trigger
                    v-bind:href="document.url"
                    text="Договор купли-продажи"
                    icon-left="download"
                    variant="dark"
                    is-underline-inverted
                    is-uppercase
                />
            </div>
        </div>
    </ui-card-base>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-cabinet-deals-item',
    props: {
        item: {
            type: Object,
        },
    },
    computed: {
        image() {
            return utils.common.injectResize(this.item.estate.image, '134x134');
        },
        stats() {
            const result = [
                { title: 'Дата', value: this.item.date },
                { title: 'Тип заявки', value: this.item.typeTitle },
                { title: 'Стоимость акции', value: this.item.priceFormatted },
                { title: 'Кол-во акций', value: this.item.amount },
            ];
            if (this.item.commission) {
                // result.push({ title: 'Комиссия', value: this.item.commissionFormatted });
                result.push({ title: 'Сумма', value: this.item.sumWithCommissionFormatted });
                // result.push({ title: 'Сумма', value: this.item.sumFormatted });
                // result.push({ title: 'Сумма с комиссией', value: this.item.sumWithCommissionFormatted });
            } else {
                result.push({ title: 'Сумма', value: this.item.sumFormatted });
            }
            return result;
        },
        isStatusFormRequired() {
            return this.item.ownerOrder?.isStatusFormRequired;
        },
        isSignAvailable() {
            return this.item.status === 'processing' &&
                this.item.ownerOrder.status === 'wait_sign';
        },
        document() {
            if ((this.item.status === 'completed' ||
                (this.item.status !== 'canceled' &&
                this.item.ownerOrder.status === 'signed' &&
                this.item.counterOrder.status === 'signed')) && this.item.document) {
                return this.item.document;
            } else if (this.item.ownerDocument) {
                return this.item.ownerDocument;
            }
            return null;
        },
        isCancelAvailable() {
            return this.item.status === 'processing' &&
                (this.item.ownerOrder.status === 'wait_sign' ||
                (this.item.ownerOrder.status === 'signed' &&
                this.item.counterOrder.status === 'wait_sign'));
        },
        statusTitle() {
            if (this.isStatusFormRequired) {
                return 'STATUS_FORM_REQUIRED';
            }
            if (this.item.status === 'processing' &&
                this.item.ownerOrder.status === 'wait_sign') {
                return 'Ожидает подтверждения';
            }
            if (this.item.status === 'processing' &&
                this.item.ownerOrder.status === 'signed' &&
                this.item.counterOrder.status === 'wait_sign') {
                return 'Ожидает подтверждения от второй стороны';
            }
            if (this.item.status === 'completed') {
                return 'Проведена';
            }
            if (this.item.status === 'canceled') {
                return 'Отменена';
            }
            if (this.item.status === 'processing') {
                return 'В обработке';
            }
            return 'Обрабатывается';
        },
        statusTheme() {
            const themeTitles = {
                'В обработке': null,
                'Проведена': 'positive',
                'STATUS_FORM_REQUIRED': 'warning',
                'Отменена': 'negative',
                'Ожидает подтверждения': 'inversed',
                'Ожидает подтверждения от второй стороны': 'fade',
            };
            return themeTitles[this.statusTitle] || null;
        },
    },
    methods: {
        statusFormHandler() {
            this.$store.commit('modals/push', {
                name: 'status-form',
                props: {
                    estate: this.item.estate,
                },
            });
        },
        signHandler() {
            this.$store.commit('modals/push', {
                name: 'market-application-confirm',
                props: {
                    deal: this.item,
                },
            });
        },
        cancelHandler() {
            this.$store.commit('modals/push', {
                name: 'generic',
                props: {
                    title: 'Отмена сделки',
                    text: 'Вы уверены, что хотите отменить сделку?',
                    buttons: [
                        {
                            text: 'Нет, вернуться',
                            theme: 'secondary-1',
                        },
                        {
                            text: 'Да, отменить',
                            callback: () => {
                                this.$store.commit('modals/pop');
                                this.$store.dispatch('market/cancelOrder', {
                                    id: this.item.ownerOrder.id,
                                });
                            },
                        },
                    ],
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-deals-item {
    padding: 8px;
    &__headline {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-image {
            flex: 0 0 auto;
            width: 67px;
            height: 67px;
            margin-right: 24px;
        }
        &-info {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            &-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 8px;
                &-status {
                    flex: 0 1 auto;
                }
                &-document {
                    .typography-caption();

                    flex: 0 1 auto;
                }
            }
            &-estate {
                .typography-medium();
            }
        }
    }
    &__botline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        border-top: 1px solid @color-gray-light;
        padding-top: 24px;
        &-stats {
            flex: 0 0 auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            &-item {
                .typography-body();

                flex: 0 0 auto;
                padding-right: 48px;
                &:last-child {
                    padding-right: 24px;
                }
                &-title {
                    margin-bottom: 8px;

                    color: @color-gray-main;
                }
                &:first-child &-title {
                    text-transform: uppercase;
                }
                &:first-child &-value {
                    color: @color-gray-main;
                }
            }
        }
        &-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 24px;
            &-item {
                flex: 0 0 auto;
                width: fit-content;
            }
        }
    }
    &__document {
        display: none;
    }
    @media @media-xl-down {
        &__botline {
            flex-direction: column;
            align-items: flex-start;
            &-actions {
                padding-top: 24px;
                width: 100%;
                justify-content: center;
            }
        }
    }
    @media @media-xl-down {
        padding: 0;
        &__headline {
            align-items: flex-start;
            &-image {
                width: 48px;
                height: 48px;
                margin-right: 16px;
            }
            &-info {
                justify-content: flex-start;
                &-top {
                    margin-bottom: 4px;
                    &-document {
                        display: none;
                    }
                }
                &-estate {
                    .typography-heading();
                }
            }
        }
        &__botline {
            padding-top: 16px;
            &-stats {
                display: block;
                &-item {
                    display: flex;
                    &-title {
                        margin-right: 4px;
                        &::after {
                            content: ':';
                        }
                    }
                }
            }
            &-actions {
                flex-direction: column;
                &-accept {
                    width: 100%;
                    max-width: 280px;
                    margin: 0 auto 24px;
                }
                &-cancel {
                    margin: 0 auto;
                    width: fit-content;
                }
            }
        }
        &__document {
            .typography-caption();

            display: block;
            width: fit-content;
            padding-top: 16px;
            border-top: 1px solid @color-gray-light;
            margin-top: 16px;
        }
    }
}
</style>
