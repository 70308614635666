import { mainClient } from '~/network';
import '~/types/store/investments';

const state = {
    generateInvestmentOfferDocumentsResult: null,
    generateInvestmentOfferDocumentsIsLoading: false,
    generateInvestmentOfferDocumentsError: null,

    issueInvestmentOfferResult: null,
    issueInvestmentOfferIsLoading: false,
    issueInvestmentOfferError: null,

    cancelExpiredInvestmentRequestsResult: null,
    cancelExpiredInvestmentRequestsIsLoading: false,
    cancelExpiredInvestmentRequestsError: null,

    calculateSharesResult: null,
    calculateSharesIsLoading: false,
    calculateSharesError: null,

    transferMoneyResult: null,
    transferMoneyIsLoading: false,
    transferMoneyError: null,

    convertEstateToSharesResult: null,
    convertEstateToSharesIsLoading: false,
    convertEstateToSharesError: null,
};

const getters = {};

const actions = {
    /**
     * Генерация документов.
     * @param {Object} state
     * @param {GenerateInvestmentOfferDocumentsPayload | {}} payload
     */
    async generateInvestmentOfferDocuments({ state }, payload = {}) {
        if (state.generateInvestmentOfferDocumentsIsLoading) {
            return;
        }
        state.generateInvestmentOfferDocumentsIsLoading = true;
        const { data, error } = await mainClient.admin.requests.generateInvestmentOfferDocuments(payload);
        if (error) {
            state.generateInvestmentOfferDocumentsError = error;
            state.generateInvestmentOfferDocumentsResult = null;
        } else if (data) {
            state.generateInvestmentOfferDocumentsError = null;
            state.generateInvestmentOfferDocumentsResult = data;
        }
        state.generateInvestmentOfferDocumentsIsLoading = false;
    },
    /**
     * Отправка инвест предложений.
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.id - Идентификатор Выпуска.
     */
    async issueInvestmentOffer({ state }, payload = {}) {
        if (state.issueInvestmentOfferIsLoading) {
            return;
        }
        state.issueInvestmentOfferIsLoading = true;
        const { data, error } = await mainClient.admin.requests.issueInvestmentOffer(payload);
        if (error) {
            state.issueInvestmentOfferError = error;
            state.issueInvestmentOfferResult = null;
        } else if (data) {
            state.issueInvestmentOfferError = null;
            state.issueInvestmentOfferResult = data;
        }
        state.issueInvestmentOfferIsLoading = false;
    },
    /**
     * Отмена не подписанных заявок в Выпуске.
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.id - Идентификатор Выпуска.
     */
    async cancelExpiredInvestmentRequests({ state }, payload = {}) {
        if (state.cancelExpiredInvestmentRequestsIsLoading) {
            return;
        }
        state.cancelExpiredInvestmentRequestsIsLoading = true;
        const { data, error } = await mainClient.admin.requests.cancelExpiredInvestmentRequests(payload);
        if (error) {
            state.cancelExpiredInvestmentRequestsError = error;
            state.cancelExpiredInvestmentRequestsResult = null;
        } else if (data) {
            state.cancelExpiredInvestmentRequestsError = null;
            state.cancelExpiredInvestmentRequestsResult = data;
        }
        state.cancelExpiredInvestmentRequestsIsLoading = false;
    },
    /**
     * Расчет количества акций.
     * @param {Object} payload
     * @param {string} payload.id - Идентификатор выпуска.
     * @param {number} payload.price - Цена за акцию инвестиции.
     * @returns {Promise<void>}
     */
    async calculateShares({ state }, payload = {}) {
        if (state.calculateSharesIsLoading) {
            return;
        }
        state.calculateSharesIsLoading = true;
        const { data, error } = await mainClient.admin.requests.calculateShares(payload);
        if (error) {
            state.calculateSharesError = error;
            state.calculateSharesResult = null;
        } else if (data) {
            state.calculateSharesError = null;
            state.calculateSharesResult = data;
        }
        state.calculateSharesIsLoading = false;
    },
    /**
     * Переводит деньги в банк (transfer money to bank for loan issue).
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.id - Идентификатор выпуска.
     * @param {Function} [onSuccess=null]
     * @returns {Promise<void>}
     */
    async transferMoney({ state }, { onSuccess = null, ...payload } = {}) {
        if (state.transferMoneyIsLoading) {
            return;
        }
        state.transferMoneyIsLoading = true;
        const { data, error } = await mainClient.admin.requests.transferMoney(payload);
        if (error) {
            state.transferMoneyError = error;
            state.transferMoneyResult = null;
        } else if (data) {
            state.transferMoneyError = null;
            state.transferMoneyResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.transferMoneyIsLoading = false;
    },
    /**
     * Выпуск акций.
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.id - Идентификатор выпуска.
     * @returns {Promise<void>}
     */
    async convertEstateToShares({ state }, payload = {}) {
        if (state.convertEstateToSharesIsLoading) {
            return;
        }
        state.convertEstateToSharesIsLoading = true;
        const { data, error } = await mainClient.admin.requests.convertEstateToShares(payload);
        if (error) {
            state.convertEstateToSharesError = error;
            state.convertEstateToSharesResult = null;
        } else if (data) {
            state.convertEstateToSharesError = null;
            state.convertEstateToSharesResult = data;
        }
        state.convertEstateToSharesIsLoading = false;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
