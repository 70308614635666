var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-investment-progress",class:[
        `_theme_${_vm.theme}`,
        {
            _empty: _vm.target > 0 && (!_vm.current || _vm.current <= 0),
            _full: _vm.current >= _vm.target,
            _with_target: _vm.isTargetVisible,
        },
    ]},[_c('div',{staticClass:"common-investment-progress__bar"},[_c('ui-progress',{attrs:{"value":_vm.target > 0 ? _vm.current / _vm.target : 1,"theme":_vm.theme}}),_c('div',{staticClass:"common-investment-progress__bar-percent",style:(_vm.percentStyle),domProps:{"textContent":_vm._s(_vm.percent + '%')}})],1),(_vm.isTargetVisible)?_c('div',{staticClass:"common-investment-progress__target",domProps:{"textContent":_vm._s(_vm.targetFormatted)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }