<template>
    <section class="page-admin-estates-item-requests">
        <template v-if="rounds && !getLoanIsLoading">
            <div class="page-admin-estates-item-requests__empty"
                v-if="rounds.length === 0"
                >
                Нет раундов
            </div>
            <template v-else>
                <div class="page-admin-estates-item-requests__round"
                    v-for="round in rounds"
                    v-bind:key="round.id"
                    v-bind:class="{
                        _expanded: expandedRounds.includes(round.id),
                        _disabled: !round.isActive,
                    }"
                    >
                    <div class="page-admin-estates-item-requests__round-title"
                        v-on:click="toggleRound(round.id)"
                        >
                        <div v-text="round.title" />
                        <span :class="[round.isActive ? 'badge' :'badge-invert']">
                             {{ round.isActive ? 'Идет сбор средств' : 'Сбор закрыт' }}
                        </span>
                        <div class="page-admin-estates-item-requests__round-title-icon">
                            <icon name="triangle" />
                        </div>
                    </div>
                    <div class="page-admin-estates-item-requests__round-list" v-if="expandedRounds.includes(round.id)">
                        <div>
                            <ui-button
                                v-on:click="() => addIssue(round.id)"
                                text="Добавить выпуск"
                                :disabled="isNewIssuePresent(round)"
                                theme="secondary-1"
                                :is-width-free="true"
                                style="display: inline-flex"
                                size="sm"
                            />
                        </div>
                        <div v-for="issue in round.issues" v-bind:key="issue.id">
                            <div class="page-admin-estates-item-requests__round-title" style="margin-top: 30px">
                                {{ getIssueTitle(issue, round.index + 1) }}
                                <span :class="[issue.status === issueStatus.NEW ? 'badge' :'badge-invert']">
                                    {{ issue.status === issueStatus.NEW ? 'Идет сбор средств' : 'Сбор закрыт' }}
                                </span>
                            </div>
                            <page-admin-estates-item-requests-actions
                                v-bind:issue="issue"
                                v-bind:round="round"
                                v-bind:estate-id="estateId"
                                v-bind:is-any-invalid="isAnyInvalid"
                            />
                            <page-admin-estates-item-requests-issue
                                v-bind:issue="issue"
                                v-bind:estate-id="estateId"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';
import { IssueStatus } from '~/constants';
import { mainClient } from '~/network';

export default {
    name: 'page-admin-estates-item-requests',
    props: {
        estateId: {
            type: Number,
        },
        isAnyInvalid: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        expandedRounds: [],
    }),
    methods: {
        toggleRound(roundId) {
            if (this.expandedRounds.includes(roundId)) {
                this.expandedRounds = this.expandedRounds.filter((item) => item !== roundId);
            } else {
                this.expandedRounds.push(roundId);
            }
        },
        async addIssue(roundId) {
            // TODO
            const { data, error } = await mainClient.estates.requests.createAdminIssue({ roundId });
            this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
        },
        isNewIssuePresent(round) {
            return round?.issues.some(i => i.status === IssueStatus.NEW);
        },
        getIssueTitle(issue, roundIndex) {
            let result = `Раунд ${roundIndex}. Выпуск ${issue.index + 1}. `;
            if (issue.status === IssueStatus.NEW) {
                result += `Собрано: ${this.formatNumber(issue.balance)}`;
            } else {
                result += `Подписано: ${this.formatNumber(issue.signedBalance)}. Собрано: ${this.formatNumber(issue.balance)}`;
            }
            return result;
        },
        formatNumber(number) {
            return utils.formats.formatNumberWithSpaces(number, true);
        },
    },
    computed: {
        ...mapState('admin/estates', [
            'getEstateRoundsIsLoading',
            'getEstateRoundsError',
            'getEstateRoundsResult',
        ]),
        ...mapState('admin/loans', [
            'getLoanIsLoading',
            'getLoanError',
            'getLoanResult',
        ]),
        /**
         * @returns {InvestmentRoundDashboardTransformed[] | null}
         */
        rounds() {
            if (!this.getEstateRoundsResult) {
                return null;
            }
            return this.getEstateRoundsResult.map(round => {
                const result = {
                    ...round,
                };
                result.title = `${round.index + 1}  раунд – ${round.investorsCount} ${utils.common.getDeclension(round.investorsCount, ['инвестор', 'инвестора', 'инвесторов'])}`;
                if (round.investorsCount > 0) {
                    result.title += ` - Подписано на сумму ${utils.formats.formatNumberWithSpaces(round.balanceSigned, true)}`;
                }
                return result;
            });
        },
        activeRound() {
            if (!this.rounds || this.rounds.length === 0) {
                return null;
            }
            return this.rounds.find((item) => item.isActive) || this.rounds[this.rounds.length - 1];
        },
        issueStatus() {
            return IssueStatus;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';
.badge {
    text-transform: uppercase;
    background-color: #9C9BA9;
    color: #F9F9FC;
    font-size: 12px;
    padding: 6px 8px 2px 8px;
    margin-left: 10px;
    display: inline;
    line-height: 12px;
    border-radius: 6px;
}
.badge-invert {
    text-transform: uppercase;
    background-color: #EFEDF1;
    color: #9C9BA9;
    font-size: 12px;
    padding: 6px 8px 2px 8px;
    margin-left: 10px;
    display: inline;
    line-height: 12px;
    border-radius: 6px;
}

.page-admin-estates-item-requests {
    padding-bottom: 80px;
    &__empty {
        .typography-body();

        color: @color-gray-main;
    }
    &__round {
        transition: opacity @duration-fast @easing-default;
        & + & {
            margin-top: 24px;
        }
        &._disabled & {
            &-title {
                color: @color-gray-main;
            }
        }
        &-title {
            .typography-medium();

            display: flex;
            align-items: center;
            width: fit-content;
            padding-bottom: 8px;

            cursor: pointer;
            &-icon {
                margin-left: 8px;

                font-size: 1.8rem;

                transform: rotate(-180deg);

                transition: transform @duration-fast ease-in-out;
                ._expanded & {
                    transform: rotate(0);
                }
            }
        }
        &-list {
            padding-left: 30px;
            border-left: 1px solid #6737af;
        }
    }
}
</style>
