import client from '../_client';
import config from '~/config';
import transforms from './transforms';
import utils from '~/utils';
import '~/types/store/investments';

/**
 * Генерация документов Выпуска.
 * @param {GenerateInvestmentOfferDocumentsPayload | {}} data
 * @returns {Promise<{ data: EstateGenerateDocuments, error: string|null, status: number }|null> | null}
 */
export const generateInvestmentOfferDocuments = (data = {}) => {
    if (!data.issueId) return null;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.investments.generateInvestmentOfferDocuments.replace('{issueId}', data.issueId),
        method: 'POST',
        data: transforms.requests.issueInvestmentOffer(data),
        // transformData: transforms.responses.adminEstateItem,
    });
};
/**
 * Отправка инвест предложений.
 * @param {Object} data
 * @param {string} id - Идентификатор Выпуска.
 * @returns {Promise<{ data: {}, error: string|null, status: number } | null> | null}
 */
export const issueInvestmentOffer = ({ id, ...data }) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.investments.issueInvestmentOffer.replace('{issueId}', id),
        method: 'POST',
        data,
        // transformData: transforms.responses.adminEstateItem,
    });
};
/**
 * Отмена не подписанных заявок в Выпуске.
 * @param {Object} data
 * @param {string} id - Идентификатор Выпуска.
 * @returns {Promise<{ data: {}, error: string|null, status: number } | null> | null}
 */
export const cancelExpiredInvestmentRequests = ({ id, ...data }) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.investments.cancelExpiredInvestmentRequests.replace('{issueId}', id),
        method: 'POST',
        data,
    });
};
/**
 * Расчет количества акций.
 * @param {Object} data
 * @param {string} data.id - Идентификатор Выпуска.
 * @param {number} data.price - Цена.
 * @returns {Promise<{ data: CalculateSharesResponse, error: string|null, status: number } | null> | null}
 */
export const calculateShares = (data = {}) => {
    if (!data.id || !data.price) return null;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.investments.calculateShares.replace('{issueId}', data.id),
        method: 'POST',
        data: {
            price: data.price,
        },
        transformData: transforms.responses.shareCalculation,
    });
};
/**
 * Переводит деньги в банк (transfer money to bank for loan issue).
 * @param {Object} data
 * @param {string} data.id - Идентификатор Выпуска.
 * @returns {Promise<{ data: {}, error: string|null, status: number } | null> | null}
 */
export const transferMoney = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.investments.transferMoney.replace('{issueId}', id),
        method: 'POST',
        data,
        // transformData: transforms.responses.shareCalculation,
    });
};
/**
 * Выпуск акций.
 * @param {Object} data
 * @param {string} data.id - Идентификатор Выпуска.
 * @returns {Promise<{ data: {}, error: string|null, status: number } | null> | null}
 */
export const convertEstateToShares = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.investments.convertEstateToShares.replace('{issueId}', id),
        method: 'POST',
        data,
        // transformData: transforms.responses.shareCalculation,
    });
};
/**
 * Поиск юзера по номеру телефона.
 * @param {Object} params
 * @param {string} params.phone - Номер телефона (+7*).
 * @returns {Promise<{ data: UserSearchByPhone[], error: string|null, status: number } | null> | null}
 */
export const searchByPhone = (params = {}) => {
    return client.request({
        url: config.api.estatesAdmin.user.searchByPhone,
        method: 'GET',
        params,
    });
};
