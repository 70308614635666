import { render, staticRenderFns } from "./admin-estates-item-modes.vue?vue&type=template&id=208fabd2&scoped=true"
import script from "./admin-estates-item-modes.vue?vue&type=script&lang=js"
export * from "./admin-estates-item-modes.vue?vue&type=script&lang=js"
import style0 from "./admin-estates-item-modes.vue?vue&type=style&index=0&id=208fabd2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "208fabd2",
  null
  
)

export default component.exports